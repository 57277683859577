import React, { useState, useEffect, useRef } from "react";
import InvoiceService from "../services/invoice.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter";
import {
  getBranches,
  getInvoicesList,
  getPlansList,
  getBranchAccounts,
  getCashlogCategories,
} from "../common/getFunctions";
import { viewPDF } from "../common/pdf";
import Loader from "../commonComponents/Loader";
import Pagination from "@material-ui/lab/Pagination";
import * as labels from "../labels/en";
import * as options from "../labels/options_en";
import commonFunctions from "../common/Functions";
import { Modal, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RequiredLabel from "../common/RequiredLabel";
import { required } from "../common/Validation";
import PullDown from "../commonComponents/PullDown";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import InternalNoteModal from "../commonComponents/InternalNoteModal";
import Switch from "react-switch";
import { NumericFormat } from "react-number-format";
import Currency from "../commonComponents/Currency";
import ExpandableCard from "../common/ExpandableCard .js";

const currentUser = JSON.parse(localStorage.getItem("user")) || null;

const Invoices = () => {
  const [error, setError] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branchFilter, setBranchFilter] = useState(currentUser.branch);
  const [planFilter, setPlanFilter] = useState("");
  const [hasAccessoriesFilter, setHasAccessoriesFilter] = useState("");
  const [keywordFilter, setKeywordFilter] = useState();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [paidFilter, setPaidFilter] = useState("");
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [numberFilter, setNumberFilter] = useState("");
  const [periodFilter, setPeriodFilter] = useState("");
  const [draftFilter, setDraftFilter] = useState("");
  const [paidModal, setPaidModal] = useState(false);
  const [invoicePaymentId, setInvoicePaymentId] = useState("");
  const [userPaying, setUserPaying] = useState("");
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentNote, setPaymentNote] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentDiscount, setPaymentDiscount] = useState(0);
  const form = useRef();
  const [currencyRate, setCurrencyRate] = useState("");
  const [branchCurrency, setBranchCurrency] = useState("");
  const [internalNoteModal, setInternalNoteModal] = useState(false);
  const [selectedUserByNote, setSelectedUserByNote] = useState("");
  const [oldInternalNote, setOldInternalNote] = useState("");
  const [plansList, setPlansList] = useState([]);
  const [saveSearch, setSaveSearch] = useState(true);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [clientView, setClientView] = useState(true);
  const [companyAccounts, setCompanyAccounts] = useState([]);
  const [account, setAccount] = useState("");
  const [searchByFilter, setSearchByFilter] = useState("");
  const [expandedCards, setExpandedCards] = useState({});
  const pageSizes = [9, 18, 24, 30, 36, 60];
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      if (
        currentUser.type === "registered" ||
        currentUser.type === "subscriber"
      )
        listInvoices(
          currentUser._id,
          "",
          currentUser.branch,
          "",
          "",
          page,
          itemsPerPage,
          "",
          "",
          "",
          "yes",
          "",
          "",
          "",
          ""
        );
      else {
        setClientView(false);
        setCompanyAccounts(await getBranchAccounts(currentUser.branch));
        setCategories(await getCashlogCategories(currentUser.branch));
        await setFilters();
      }
    }

    onReady();
  }, []);

  async function setFilters() {
    let data = null;
    let saveSearchT = localStorage.getItem("invoiceSaveSearch") || false;
    saveSearchT = saveSearchT && saveSearchT === "true" ? true : false;
    if (saveSearchT) {
      let filters = localStorage.getItem("invoiceFilters") || null;
      data = filters ? JSON.parse(filters) : "";
    }
    setSaveSearch(saveSearchT);
    const branchFilterT =
      data &&
      data["branch"] &&
      data["branch"] != "undefined" &&
      data["branch"] != "null"
        ? data["branch"]
        : currentUser.branch;
    const typeFilterT =
      data && data["typeFilter"] && data["typeFilter"] != "undefined"
        ? data["typeFilter"]
        : "";
    const keywordFilterT =
      data && data["keywordFilter"] && data["keywordFilter"] != "undefined"
        ? data["keywordFilter"]
        : "";
    const startT =
      data && data["startFilter"] && data["startFilter"] != "undefined"
        ? new Date(data["startFilter"])
        : "";
    const endT =
      data &&
      data["endFilter"] &&
      data["endFilter"] != "undefined" &&
      data["endFilter"] != "null"
        ? new Date(data["endFilter"])
        : "";
    const paidFilterT =
      data &&
      data["paidFilter"] &&
      data["paidFilter"] != "undefined" &&
      data["paidFilter"] != "null"
        ? data["paidFilter"]
        : "";
    const numberFilterT =
      data &&
      data["numberFilter"] &&
      data["numberFilter"] != "undefined" &&
      data["numberFilter"] != "null"
        ? data["numberFilter"]
        : "";
    const periodFilterT =
      data &&
      data["periodFilter"] &&
      data["periodFilter"] != "undefined" &&
      data["periodFilter"] != "null"
        ? data["periodFilter"]
        : "";
    const planFilterT =
      data && data["planFilter"] && data["planFilter"] != "undefined"
        ? data["planFilter"]
        : "";
    const draftFilterT =
      data && data["draftFilter"] && data["draftFilter"] != "undefined"
        ? data["draftFilter"]
        : "";
    const hasAccessoriesFilterT =
      data &&
      data["hasAccessoriesFilter"] &&
      data["hasAccessoriesFilter"] != "undefined"
        ? data["hasAccessoriesFilter"]
        : "";

    const searchByFilterT =
      data && data["searchByFilter"] && data["searchByFilter"] != "undefined"
        ? data["searchByFilter"]
        : "";
    setBranches(await getBranches());
    await setPlansList(await getPlansList(branchFilterT));
    setDraftFilter(draftFilterT);
    setHasAccessoriesFilter(hasAccessoriesFilterT);
    setBranchFilter(branchFilterT);
    setTypeFilter(typeFilterT);
    setKeywordFilter(keywordFilterT);
    setStart(startT);
    setEnd(endT);
    setPaidFilter(paidFilterT);
    setNumberFilter(numberFilterT);
    setPeriodFilter(periodFilterT);
    setPlanFilter(planFilterT);
    setSearchByFilter(searchByFilterT);
    listInvoices(
      "",
      "",
      branchFilterT,
      typeFilterT,
      "",
      page,
      itemsPerPage,
      keywordFilterT,
      startT,
      endT,
      paidFilterT,
      numberFilterT,
      periodFilterT,
      planFilterT,
      draftFilterT,
      hasAccessoriesFilterT,
      searchByFilterT
    );
  }
  const listInvoices = async (
    bikerId,
    onlySent,
    branch,
    typeFilter,
    onlyList,
    page,
    itemsPerPage,
    keywordFilter,
    startFilter,
    endFilter,
    paidFilter,
    numberFilter,
    periodFilter,
    planFilter,
    draftFilter,
    hasAccessoriesFilter,
    searchByFilter
  ) => {
    setLoading(true);
    if (
      currentUser.type === "registered" ||
      currentUser.type === "subscriber"
    ) {
      const currentUser = JSON.parse(localStorage.getItem("user")) || null;
      bikerId = currentUser._id;
      branch = currentUser.branch;
    }
    let data = await getInvoicesList(
      bikerId,
      onlySent,
      branch,
      typeFilter,
      onlyList,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      keywordFilter,
      startFilter,
      endFilter,
      paidFilter,
      numberFilter,
      periodFilter,
      planFilter,
      draftFilter,
      searchByFilter,
      hasAccessoriesFilter
    );
    setInvoices(data.list);
    setTotal(data ? data.total : 0);
    setCount(Math.ceil(data.total / itemsPerPage));
    setResetMsg("");

    if (saveSearch)
      localStorage.setItem(
        "invoiceFilters",
        JSON.stringify({
          branch,
          typeFilter,
          keywordFilter,
          startFilter,
          endFilter,
          paidFilter,
          numberFilter,
          periodFilter,
          planFilter,
          draftFilter,
          hasAccessoriesFilter,
          searchByFilter,
        })
      );
    setLoading(false);
  };
  const sendInvoice = async (id, name, bikerId, packageId) => {
    if (
      window.confirm(
        "Are you sure you want to send this invoice to " + name + "?"
      )
    ) {
      InvoiceService.send({
        id: id,
        bikerId: bikerId,
        packageId: packageId,
      }).then(
        (res) => {
          alert("Invoice is sent!");
          setInvoices(
            invoices.map((i) => {
              if (i._id === id) {
                i.sent = 1;
              }
              return i;
            })
          );
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const deleteInvoice = async (id) => {
    let inv = invoices.filter((invoice) => invoice._id === id);
    let mssg = "Are you sure you want to delete this Invoice?";
    if (inv[0].type === "Standard")
      mssg =
        "You are about to delete an invoice! \nAre you aware of what you are doing? \n\nDelete invoice?";
    if (window.confirm(mssg)) {
      InvoiceService.deleteInvoice(id).then(
        () => {
          alert("Invoice deleted!");
          setInvoices(invoices.filter((invoice) => invoice._id !== id));
          setTotal(total - 1);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const branchHandler = async (branchID) => {
    setBranchFilter(branchID);
    setPlansList(await getPlansList(branchID));
    setCompanyAccounts(await getBranchAccounts(branchID));
    setCategories(await getCashlogCategories(branchID));
    // in this case we need to get the new list of bikers.
    resetResults();
  };
  const planHandler = async (p) => {
    setPlanFilter(p);
    resetResults();
  };

  const handleKeywordChange = (val) => {
    setKeywordFilter(val);
    resetResults();
  };

  const handleNumberChange = (val) => {
    setNumberFilter(val);
    resetResults();
  };

  const handlePeriodChange = (val) => {
    const re = /^[0-9\b]+$/;
    if (val === "" || re.test(val)) {
      setPeriodFilter(val);
      resetResults();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };
  const handleTypeChange = (type) => {
    setTypeFilter(type);
    resetResults();
  };
  const handleDraftFilter = (f) => {
    setDraftFilter(f);
    resetResults();
  };
  const handleHasAccessoriesFilter = (f) => {
    setHasAccessoriesFilter(f);
    resetResults();
  };

  const handleSearchByFilter = (f) => {
    setSearchByFilter(f);
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    localStorage.setItem("invoiceFilters", "");
    setInvoices([]);
    setResetMsg(labels.RESET_MSG);
  };
  const handlePageChange = async (event, value) => {
    await setPage(value);
    await listInvoices(
      "",
      "",
      branchFilter,
      typeFilter,
      false,
      value,
      itemsPerPage,
      keywordFilter,
      start,
      end,
      paidFilter,
      numberFilter,
      periodFilter,
      planFilter,
      draftFilter,
      hasAccessoriesFilter,
      searchByFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listInvoices(
      "",
      "",
      branchFilter,
      typeFilter,
      false,
      1,
      newItemsPerPage,
      keywordFilter,
      start,
      end,
      paidFilter,
      numberFilter,
      periodFilter,
      planFilter,
      draftFilter,
      hasAccessoriesFilter,
      searchByFilter
    );
  };

  const triggerSearch = async () => {
    await setPage(1); // return to first page
    await listInvoices(
      "",
      "",
      branchFilter,
      typeFilter,
      false,
      1,
      itemsPerPage,
      keywordFilter,
      start,
      end,
      paidFilter,
      numberFilter,
      periodFilter,
      planFilter,
      draftFilter,
      hasAccessoriesFilter,
      searchByFilter
    );
  };
  const resetSearch = async () => {
    setBranchFilter("");
    setPlanFilter("");
    setTypeFilter("");
    setKeywordFilter("");
    setStart("");
    setEnd("");
    setSearchByFilter("");
    listInvoices(
      "",
      "",
      "",
      "",
      "",
      page,
      itemsPerPage,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const markInvoiceAsPaidUnpaid = async (id, paid) => {
    let msg = paid
      ? "Are you sure you want to mark this invoice as unpaid?\n\n This will delete all previous payments!"
      : "This will add a new payment log. Continue?";
    let p = paid ? "false" : "true";
    if (!paid && (paymentAmount == "" || paymentAmount == 0)) {
      msg = "Are you sure you want to save this with 0 payment? ";
    }
    if (
      !paid &&
      paymentAmount != "" &&
      paymentAmount != "0" &&
      (category == "" || account == "")
    ) {
      alert("Please fill all required fields");
      return;
    }
    const discountValue = commonFunctions.removeComma(paymentDiscount) || 0;
    if (window.confirm(msg)) {
      let data = {
        id: id,
        paid: p,
        paymentDate: paymentDate,
        paymentNote: paymentNote,
        paymentMethod: paymentMethod,
        account: account,
        currencyRate: currencyRate || branchCurrency,
        completed: paymentCompleted,
        discount: discountValue,
        paymentAmount: commonFunctions.removeComma(paymentAmount),
        userBalance: commonFunctions.removeComma(userBalance),
        category: category,
      };

      InvoiceService.pay(data).then(
        () => {
          setInvoices(
            invoices.map((i, index) => {
              if (!paid && i.user && i.user._id == userPaying) {
                i.user.userBalance = userBalance;
              }
              if (i._id === id) {
                let remaining = i.remaining_amount;

                var paid_amount = Number(data.paymentAmount);

                if (!i.remaining_amount) {
                  remaining = i.final_amount;
                }

                if (paymentMethod !== "USD") {
                  paid_amount =
                    Number(data.paymentAmount) / Number(data.currencyRate); // convert to usd
                }

                remaining =
                  Number(remaining) -
                  Number(paid_amount) -
                  Number(discountValue); // discount is always in usd

                i.paid = !paid;
                if (i.paid) {
                  i.paymentDate = paymentDate.toISOString();
                  i.paymentNote = paymentNote;
                  i.realpaymentMethod = paymentMethod;
                  i.currencyRate = currencyRate || branchCurrency;
                  i.completed = i.paid ? paymentCompleted : false;
                  i.remaining_amount = remaining;
                  i.paymentDiscount = paymentDiscount;
                  i.discount = Number(i.discount) + Number(discountValue);
                } else {
                  i.discount = 0;
                }
              }
              return i;
            })
          );
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
    handlePaidModalClose();
  };

  const handlePaidFilterChange = (paid) => {
    setPaidFilter(paid);
    resetResults();
  };

  const handlePaidModalShow = (id, branchCurrencyRate, user_balance, uid) => {
    setInvoicePaymentId(id);
    setUserPaying(uid);
    setBranchCurrency(branchCurrencyRate);
    setUserBalance(user_balance || 0);
    setPaidModal(true);
  };

  const handlePaidModalClose = () => {
    setInvoicePaymentId("");
    setPaymentDate(new Date());
    setPaymentNote("");
    setPaymentAmount("");
    setUserBalance("");
    setCategory("");
    setUserPaying("");
    setPaymentCompleted(false);
    setPaymentMethod("USD");
    setCurrencyRate("");
    setBranchCurrency("");
    setPaymentDiscount(0);
    setPaidModal(false);
  };

  const handleAddInternalNoteClick = (id, internalNote) => {
    setSelectedUserByNote(id);
    setOldInternalNote(internalNote);
    setInternalNoteModal(true);
  };

  const handleSaveSearchChange = () => {
    localStorage.setItem("invoiceSaveSearch", (!saveSearch).toString());
    setSaveSearch(!saveSearch);
  };

  const triggerAccountChanged = (a) => {
    setAccount(a);
    const selectedAccount = companyAccounts.find((acc) => acc.key === a);
    setPaymentMethod(selectedAccount.currency);
  };
  const renderModels = () => {
    return (
      <>
        <InternalNoteModal
          internalNoteModal={internalNoteModal}
          setInternalNoteModal={setInternalNoteModal}
          selectedUserByNote={selectedUserByNote}
          oldInternalNote={oldInternalNote}
          list={invoices}
          setList={setInvoices}
          isSubscription
        />
        <Modal show={paidModal} onHide={handlePaidModalClose}>
          <Modal.Header>
            <Modal.Title>Add Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              ref={form}
            >
              <div className="form-group">
                <RequiredLabel htmlFor="paymentDate" text="Payment Date" />
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  name="paymentDate"
                  selected={paymentDate}
                  onChange={(date) => setPaymentDate(date)}
                  validations={[required]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="paymentNote">Payment Note</label>
                <Textarea
                  style={{ width: "100%", borderColor: "#dee2e6" }}
                  onChange={(e) => setPaymentNote(e.target.value)}
                  rows="3"
                  value={paymentNote}
                />
              </div>

              <div className="form-group">
                <label htmlFor="paymentAmount">Amount</label>
                <NumericFormat
                  type="text"
                  className="form-control"
                  name="paymentAmount"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  decimalSeparator="."
                  value={paymentAmount}
                  onChange={(e) => setPaymentAmount(e.target.value)}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="userBalance">User Balance</label>
                <NumericFormat
                  type="text"
                  className="form-control"
                  name="userBalance"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  decimalSeparator="."
                  value={userBalance}
                  onChange={(e) => setUserBalance(e.target.value)}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="paymentDiscount">Discount (USD)</label>
                <NumericFormat
                  type="text"
                  className="form-control"
                  name="paymentDiscount"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  decimalSeparator="."
                  value={paymentDiscount}
                  onChange={(e) => setPaymentDiscount(e.target.value)}
                  validations={[required]}
                />
              </div>
              <div className="form-group">
                <RequiredLabel htmlFor="category" text="Category" />
                <PullDown
                  defaultValue={category}
                  labelInside={false}
                  pulldownFor={"category"}
                  forceBlank={true}
                  setValue={(value) => {
                    setCategory(value);
                  }}
                  options={categories}
                />
              </div>
              <div className="form-group">
                <RequiredLabel htmlFor="account" text="Account" />
                <PullDown
                  defaultValue={account}
                  labelInside={false}
                  pulldownFor={"account"}
                  forceBlank={true}
                  setValue={(value) => {
                    triggerAccountChanged(value);
                  }}
                  options={companyAccounts}
                />
              </div>
              <div className="form-group">
                <Currency
                  fn={(e) => setPaymentMethod(e.target.value)}
                  name="paymentMethod"
                  disableField={true}
                  value={paymentMethod}
                  isRequired={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="currencyRate">Currency Rate</label>
                <Input
                  type="text"
                  className="form-control"
                  name="currencyRate"
                  value={currencyRate || branchCurrency}
                  onChange={(e) => setCurrencyRate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="paymentCompleted">Completed</label>
                <Switch
                  onChange={() => setPaymentCompleted(!paymentCompleted)}
                  checked={paymentCompleted}
                />
              </div>
            </Form>
          </Modal.Body>
          <button
            style={{ width: "auto", margin: "10px" }}
            variant="primary"
            onClick={() => markInvoiceAsPaidUnpaid(invoicePaymentId, false)}
          >
            Save
          </button>
        </Modal>
      </>
    );
  };
  const renderRightLinks = () => {
    return (
      <>
        {!clientView &&
        (currentUser.type === "admin" ||
          currentUser.type === "helpdesk" ||
          currentUser.type === "manager") ? (
          <div className="floatRight ">
            <NavLink to={"/invoice/new"} className="greenLink mx-0">
              Add Invoice
            </NavLink>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };
  const renderFilters = () => {
    return (
      <>
        {!clientView ? (
          <>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="filterContainer"
            >
              <Row style={{ display: "flex", flexDirection: "row" }}>
                <Col md={2}>
                  <label
                    htmlFor="paid"
                    className="filterLabelInput maintenanceLabels"
                  >
                    Paid
                  </label>

                  <select
                    className="form-control filterInput2"
                    value={paidFilter}
                    onChange={(e) => handlePaidFilterChange(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </Col>

                <Col md={2}>
                  <label
                    className="filterLabelInput maintenanceLabels"
                    htmlFor="keywordFilter"
                  >
                    Keyword
                  </label>
                  <input
                    type="text"
                    className="form-control filterInput2"
                    name="keywordFilter"
                    value={keywordFilter}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => handleKeywordChange(e.target.value)}
                  />
                </Col>
                <Col md={2}>
                  <label
                    htmlFor="invoice_type "
                    className="filterLabelInput maintenanceLabels"
                  >
                    Type
                  </label>

                  <select
                    className="form-control filterInput2"
                    value={typeFilter}
                    onChange={(e) => handleTypeChange(e.target.value)}
                  >
                    <option value="">All</option>
                    {options.invoice_types.map((item, index) => (
                      <option key={"invoice_type_" + index} value={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={2}>
                  <label
                    htmlFor="draft"
                    className="filterLabelInput maintenanceLabels"
                  >
                    Draft
                  </label>

                  <select
                    className="form-control filterInput2"
                    value={draftFilter}
                    onChange={(e) => handleDraftFilter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </Col>

                <Col md={2}>
                  <table style={{ marginTop: 40 }} border="0">
                    <tr>
                      <td>
                        <button
                          onClick={(e) => triggerSearch(e)}
                          className="btn btn-primary btn-block search-button "
                        >
                          Go
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={(e) => resetSearch(e)}
                          className="btn btn-primary btn-block reset-button "
                        >
                          Reset
                        </button>
                      </td>
                    </tr>
                  </table>
                </Col>
                <Col style={{ marginTop: 40 }} md={2}>
                  <a
                    href="javascript:void(0)"
                    onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                    className="greenLink mx-0"
                  >
                    {showAdvancedFilters ? "Hide" : "Show"} Advanced
                  </a>
                  <div className="d-flex flex-row align-items-center">
                    <input
                      onChange={handleSaveSearchChange}
                      style={{ width: "auto", margin: "5px" }}
                      type="checkbox"
                      checked={saveSearch}
                    />
                    <label
                      style={{
                        width: "auto",
                        margin: "0px",
                        padding: "0px",
                        color: "#343a40",
                        fontSize: 14,
                      }}
                    >
                      Save Search
                    </label>
                  </div>
                </Col>
              </Row>
              {showAdvancedFilters ? (
                <Row style={{ display: "flex", flexDirection: "row" }}>
                  <Col md={2}>
                    <label
                      htmlFor="searchByFilter"
                      className="filterLabelInput maintenanceLabels"
                    >
                      Search By
                    </label>

                    <select
                      className="form-control filterInput2"
                      value={searchByFilter}
                      onChange={(e) => handleSearchByFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="client">Client</option>
                      <option value="company">Company</option>
                    </select>
                  </Col>

                  <Col md={2}>
                    <label
                      htmlFor="start"
                      className="filterLabelInput maintenanceLabels"
                    >
                      From
                    </label>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      className="form-control filterInput2"
                      name="start"
                      selected={start}
                      onChange={(date) => setStart(date)}
                    />
                  </Col>
                  <Col md={2}>
                    <label
                      htmlFor="to"
                      className="filterLabelInput maintenanceLabels"
                    >
                      To
                    </label>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      className="form-control filterInput2"
                      name="end"
                      selected={end}
                      onChange={(date) => setEnd(date)}
                    />
                  </Col>
                  <Col md={2}>
                    <label
                      className="filterLabelInput maintenanceLabels"
                      htmlFor="numberFilter"
                    >
                      Number
                    </label>
                    <input
                      type="text"
                      className="form-control filterInput2"
                      name="numberFilter"
                      value={numberFilter}
                      onKeyDown={(e) => handleKeyDown(e)}
                      onChange={(e) => handleNumberChange(e.target.value)}
                    />
                  </Col>

                  <Col md={2}>
                    <label
                      className="filterLabelInput maintenanceLabels"
                      htmlFor="periodFilter"
                    >
                      Period
                    </label>
                    <input
                      type="text"
                      className="form-control filterInput2"
                      name="periodFilter"
                      value={periodFilter}
                      onKeyDown={(e) => handleKeyDown(e)}
                      onChange={(e) => handlePeriodChange(e.target.value)}
                    />
                  </Col>

                  {currentUser && currentUser.type === "admin" ? (
                    <Col md={2}>
                      <label
                        className="filterLabelInput maintenanceLabels"
                        htmlFor="branch"
                      >
                        Branch
                      </label>

                      <select
                        className="form-control filterInput2"
                        onChange={(e) => branchHandler(e.target.value)}
                        value={branchFilter}
                      >
                        <option value="" key="0">
                          All
                        </option>
                        {branches.map((branch, index) => (
                          <option key={index} value={branch._id}>
                            {branch.label}
                          </option>
                        ))}
                      </select>
                    </Col>
                  ) : null}

                  <Col md={2}>
                    <label
                      className="filterLabelInput maintenanceLabels"
                      htmlFor="plan"
                    >
                      Plan
                    </label>

                    <select
                      className="form-control filterInput2"
                      onChange={(e) => planHandler(e.target.value)}
                      value={planFilter}
                    >
                      <option value="" key="0">
                        All
                      </option>
                      {plansList.map((p, index) => (
                        <option key={index} value={p._id}>
                          {p.label}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md={2}>
                    <label
                      htmlFor="draft"
                      className="filterLabelInput maintenanceLabels"
                    >
                      Has Accessories
                    </label>

                    <select
                      className="form-control filterInput2"
                      value={hasAccessoriesFilter}
                      onChange={(e) =>
                        handleHasAccessoriesFilter(e.target.value)
                      }
                    >
                      <option value="">All</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </Col>
                </Row>
              ) : null}
            </div>
          </>
        ) : null}
      </>
    );
  };

  // Handles individual card toggle
  const onToggleCard = (id, expanded) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [id]: expanded,
    }));
  };

  // Expand all cards
  const handleExpandAll = () => {
    const newExpandedState = invoices.reduce((acc, invoice) => {
      acc[invoice._id] = true; // Set all cards to expanded
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to expanded for all
  };

  // Collapse all cards
  const handleCollapseAll = () => {
    const newExpandedState = invoices.reduce((acc, invoice) => {
      acc[invoice._id] = false; // Set all cards to collapsed
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to collapsed for all
  };

  const renderInvoiceCard = (invoice, index) => {
    const isExpanded = expandedCards[invoice._id] || false;
    let displayedCurrency = invoice.currency;
    let currencyRate = 1;
    if (invoice.paymentMethod == "Cash-USD" && invoice.currency !== "USD") {
      displayedCurrency = "$";
      currencyRate = invoice.currencyRate;
    } else if (
      invoice.paymentMethod == "Cash-Euro" &&
      invoice.currency != "Euro"
    ) {
      displayedCurrency = "€";
      currencyRate = invoice.currencyRate;
    } else if (
      invoice.paymentMethod == "Cash-LBP" &&
      invoice.currency != "LBP"
    ) {
      displayedCurrency = "LBP";
      currencyRate = invoice.currencyRate;
    }
    const rentedAccessories = invoice.accessories
      ? invoice.accessories.filter(
          (acc) => acc.usage === "Rent" || acc.usage === 0 || acc.usage === "0"
        )
      : [];
    const rentedAccessoriesCount = rentedAccessories.length;
    const accessoriesList = rentedAccessories.map((acc, index, array) => {
      const comma = index < array.length - 1 ? ", " : "";
      return (
        <span key={index}>
          {acc.inventoryLabel}
          {comma}
        </span>
      );
    });
    const accessoriesListString = rentedAccessories
      .map((acc) => acc.inventoryLabel) // Extract inventoryLabel from each accessory
      .join(", "); // Join them into a single string with commas

    const header = (
      <>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {/* Header row with expand icon, name, and InternalNoteComponent */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {clientView ? (
                <>{invoice.number}</>
              ) : invoice.user ? (
                currentUser.type !== "accountant" ? (
                  <NavLink
                    className="text-dark"
                    to={"/user/" + invoice.user._id}
                  >
                    {invoice.user ? invoice.user.full_name : ""}
                  </NavLink>
                ) : (
                  <> {invoice.user ? invoice.user.full_name : ""}</>
                )
              ) : invoice.package ? (
                invoice.package.title
              ) : invoice.company ? (
                invoice.company.name
              ) : (
                ""
              )}
              {/* Action Icons row */}
              {!clientView && currentUser.type !== "accountant" && (
                <>
                  <NavLink
                    to={"/invoice/" + invoice._id}
                    className="text-dark midText"
                    style={{ marginLeft: "10px" }}
                  >
                    <i alt="View" title="View" className="fa fa-binoculars"></i>
                  </NavLink>
                  &nbsp;&nbsp;
                </>
              )}
              <a
                href="javascript:void(0)"
                style={{ marginLeft: "10px" }}
                onClick={() =>
                  viewPDF(invoice._id, invoice.sent ? invoice.fileName : "")
                }
                className="text-dark midText"
              >
                <i alt="PDF" title="PDF" className="fa fa-file-pdf"></i>
              </a>
              {clientView ? (
                <>
                  <NavLink
                    to={"/invoice/" + invoice._id}
                    className="text-dark midText"
                    style={{ marginLeft: "10px" }}
                  >
                    <i
                      alt="Details"
                      title="Details"
                      className="fa fa-binoculars"
                    ></i>
                  </NavLink>
                </>
              ) : invoice.sent || currentUser.type === "accountant" ? (
                ""
              ) : (
                <NavLink
                  to={
                    invoice.type === "Standard"
                      ? "/invoice/edit/" + invoice._id + "?subscription"
                      : "/invoice/edit/" + invoice._id
                  }
                  className="text-dark midText"
                  style={{ marginLeft: "10px" }}
                >
                  <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                </NavLink>
              )}
            </div>
            {!clientView ? (
              <InternalNoteComponent
                list={invoices}
                setList={setInvoices}
                oldInternalNote={invoice.user ? invoice.user.internalNote : ""}
                selectedUserByNote={invoice.user ? invoice.user._id : ""}
                note={
                  invoice.paid && invoice.paymentNote ? invoice.paymentNote : ""
                }
                rentedAcc={accessoriesListString || ""}
                clickable={false}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );

    const body = (
      <>
        {!clientView ? (
          <>
            {!invoice.draft && currentUser.type !== "accountant" ? (
              <>
                <a
                  href="javascript:void(0)"
                  onClick={() =>
                    sendInvoice(
                      invoice._id,
                      invoice.user
                        ? invoice.user.full_name
                        : invoice.company.name,
                      invoice.user ? invoice.user._id : "",
                      invoice.package ? invoice.package._id : ""
                    )
                  }
                  className="text-dark midText"
                >
                  {invoice.sent ? (
                    <i title="Resend" alt="Resend" className="fa fa-share"></i>
                  ) : invoice.type === "Standard" ||
                    invoice.type === "Surchage" ? (
                    <i
                      title="Send"
                      alt="Send"
                      className="fa fa-paper-plane"
                    ></i>
                  ) : (
                    <>
                      <i
                        title="Send Receipt"
                        alt="Send Receipt"
                        className="fas fa-receipt"
                      ></i>
                    </>
                  )}
                </a>
              </>
            ) : (
              ""
            )}
            {currentUser &&
            (currentUser.type === "admin" || currentUser.type === "manager") &&
            !invoice.paid ? (
              <>
                <a
                  href="javascript:void(0)"
                  className="red text-dark midText"
                  onClick={() => deleteInvoice(invoice._id)}
                  style={{ marginLeft: "10px" }}
                >
                  Delete
                </a>
              </>
            ) : null}
            {!invoice.draft &&
            !invoice.completed &&
            currentUser.type !== "accountant" ? (
              <>
                <a
                  href="javascript:void(0)"
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    handlePaidModalShow(
                      invoice._id,
                      invoice.branch.currencyRate,
                      invoice.user?.userBalance,
                      invoice.user?._id
                    )
                  }
                  alt="Add Payment"
                  title="Add Payment"
                  className="text-dark small-link midText"
                >
                  Add Payment
                </a>
              </>
            ) : null}
            {!invoice.draft &&
            invoice.paid &&
            currentUser.type !== "accountant" ? (
              <>
                <br />
                <a
                  href="javascript:void(0)"
                  onClick={() =>
                    markInvoiceAsPaidUnpaid(invoice._id, invoice.paid)
                  }
                  alt="Mark as unpaid"
                  title="Mark as unpaid"
                  className="text-dark small-link midText"
                >
                  Mark as unpaid
                </a>
                &nbsp;&nbsp;
                <a
                  href="javascript:void(0)"
                  onClick={() =>
                    viewPDF(
                      invoice._id,
                      invoice.sent ? invoice.fileName : "",
                      false,
                      true
                    )
                  }
                  alt="Regenerate PDF"
                  title="Regenerate PDF"
                  className="text-dark small-link midText"
                >
                  Regenerate PDF
                </a>
              </>
            ) : (
              ""
            )}
            <br />
            {invoice.user && currentUser.type !== "accountant" ? (
              <>
                <button
                  className="linkBtn greenLink mx-0"
                  onClick={() =>
                    handleAddInternalNoteClick(
                      invoice.user._id,
                      invoice.user.internalNote
                    )
                  }
                >
                  <i className="fa fa-comment">
                    {" "}
                    <span className="faText">Add an internal note</span>
                  </i>
                </button>
                <br />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {invoice.type === "Standard" && invoice.plan ? (
          <>
            <span className="tinyCardLabel">Plan:&nbsp;</span>
            <span className="midText">{invoice.plan.label}</span>
            <br />
          </>
        ) : (
          ""
        )}
        {invoice.number ? (
          <>
            <span className="tinyCardLabel">Number:&nbsp;</span>
            <span className="midText">{invoice.number}</span>{" "}
            {clientView && invoice.completed ? (
              <a
                href="javascript:void(0)"
                onClick={() =>
                  viewPDF(invoice._id, invoice.sent ? invoice.fileName : "")
                }
                className="text-dark midText"
              >
                <i alt="PDF" title="PDF" className="fa fa-file-pdf"></i>
              </a>
            ) : (
              ""
            )}
            <br />
          </>
        ) : (
          ""
        )}
        {!clientView ? (
          <>
            <span className="tinyCardLabel">Type:&nbsp;</span>
            <span className="midText">
              {invoice.type === "Standard" ? (
                <span>Subscription Fee</span>
              ) : invoice.type === "Surchage" ? (
                "Short Period Surchage"
              ) : (
                invoice.type
              )}
            </span>
            <br />
            {rentedAccessoriesCount > 0 && (
              <>
                <span className="tinyCardLabel">
                  Rented Accessories{" "}
                  <span className="red bold">({rentedAccessoriesCount})</span>
                  :&nbsp;
                </span>
                <span className="midText red">{accessoriesList}</span>
                <br />
              </>
            )}
            <span className="tinyCardLabel">Draft:&nbsp;</span>
            <span className="midText">
              {invoice.draft ? (
                <span style={{ color: "red" }}>Yes</span>
              ) : (
                <span style={{ color: "green" }}>No</span>
              )}
            </span>
            <br />
            <span className="tinyCardLabel">
              Displayed Payment Method:&nbsp;
            </span>
            <span className="midText">{invoice.paymentMethod}</span>
            <br />

            {invoice.paid && invoice.realpaymentMethod ? (
              <>
                <span className="tinyCardLabel">
                  Internal Payment Method:&nbsp;
                </span>
                <span className="midText">{invoice.realpaymentMethod}</span>
                <br />
              </>
            ) : (
              ""
            )}
            <span className="tinyCardLabel">Fee:&nbsp;</span>
            <span className="midText">
              {commonFunctions.CommaFormatted(
                invoice.total_amount * currencyRate
              )}{" "}
              {displayedCurrency}
            </span>
            <br />
            <span className="tinyCardLabel">
              <b>Total Fee:</b>&nbsp;
            </span>
            <span className="midText">
              {commonFunctions.CommaFormatted(
                invoice.final_amount * currencyRate
              )}{" "}
              {displayedCurrency}
            </span>
            <br />
          </>
        ) : null}
        <span className="tinyCardLabel">
          <b>Total Fee{!clientView ? " Origin" : null}:</b>
          &nbsp;
        </span>
        <span className="midText">
          {commonFunctions.CommaFormatted(invoice.final_amount * 1) +
            " " +
            invoice.currency}
        </span>

        <br />
        {invoice.discount && invoice.discount != 0 ? (
          <>
            {" "}
            <span className="tinyCardLabel">
              <b>Discount:</b>&nbsp;
            </span>
            <span className="midText red">
              {commonFunctions.CommaFormatted(invoice.discount * 1) +
                " " +
                "USD"}
            </span>
            <br />
            <span className="tinyCardLabel">
              <b>Total Fee After discount:</b>&nbsp;
            </span>
            <span className="midText red">
              {commonFunctions.CommaFormatted(
                invoice.final_amount - invoice.discount * 1
              ) +
                " " +
                "USD"}
            </span>
            <br />
          </>
        ) : (
          ""
        )}
        {invoice.completed || !invoice.remaining_amount ? null : (
          <>
            <span className="tinyCardLabel">
              <b>Remaining Amount{!clientView ? " Origin" : null}:</b>
              &nbsp;
            </span>
            <span className="midText">
              {commonFunctions.CommaFormatted(invoice.remaining_amount * 1) +
                " " +
                invoice.currency}
            </span>
            <br />
          </>
        )}
        {invoice.type === "Standard" ? (
          <>
            <span className="tinyCardLabel">Start-End:&nbsp;</span>
            <span className="midText">
              {DateFormatter.DateFormatterOne(invoice.start)} -{" "}
              {DateFormatter.DateFormatterOne(invoice.end)}
            </span>
            <br />
          </>
        ) : (
          ""
        )}
        <span className="tinyCardLabel">Date:&nbsp;</span>
        <span className="midText">
          {DateFormatter.DateFormatterOne(invoice.date)}
        </span>
        <br />
        {invoice.type === "Standard" ? (
          <>
            <span className="tinyCardLabel">Period:&nbsp;</span>
            <span className="midText">
              {invoice.period ? invoice.period : "1"}
            </span>
            <br />
          </>
        ) : (
          ""
        )}
        {invoice.currencyRate ? (
          <>
            <span className="tinyCardLabel">Currency Rate:&nbsp;</span>
            <span className="midText">{invoice.currencyRate}</span>
            <br />
          </>
        ) : (
          ""
        )}
        {invoice.paid && invoice.completed ? (
          <span className="midText green">
            Paid{" "}
            {invoice.paymentDate ? (
              <span>
                {" "}
                at {DateFormatter.DateFormatterOne(invoice.paymentDate)}
              </span>
            ) : (
              ""
            )}
          </span>
        ) : invoice.paid ? (
          <span className="midText red">Payment not completed.</span>
        ) : (
          <span className="midText red">Not Paid</span>
        )}
        <br />
        {invoice.package && invoice.company ? (
          <>
            <span className="tinyCardLabel">Company Name:&nbsp;</span>
            <span className="midText">{invoice.company.name}</span>
            <br />
          </>
        ) : (
          ""
        )}
        {invoice.package ? (
          <>
            <span className="tinyCardLabel">Package Name:&nbsp;</span>
            <span className="midText">{invoice.package.title}</span>
            <br />
          </>
        ) : (
          ""
        )}
      </>
    );
    return (
      <ExpandableCard
        header={header}
        body={body}
        onToggleCard={onToggleCard}
        isExpanded={isExpanded}
        id={invoice._id}
      />
    );
  };

  return (
    <div className="container">
      {renderModels()}
      {renderRightLinks()}

      <div>
        <h3>{!clientView ? "Invoices List" : "Payment History"} </h3>
        {renderFilters()}
      </div>
      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}
      {!error && invoices.length !== 0 ? (
        <div>
          <Row>
            <Col>
              <span style={{ float: "right", marginLeft: 10 }}>
                {total} results
              </span>
              <span style={{ float: "left", marginRight: 10 }}>
                <a
                  className="greenLink mx-0"
                  href="javascript:void(0)"
                  onClick={handleExpandAll}
                >
                  Expand All
                </a>{" "}
                <a
                  className="greenLink mx-0"
                  href="javascript:void(0)"
                  onClick={handleCollapseAll}
                >
                  Collapse All
                </a>
              </span>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "first baseline",
            }}
          >
            {invoices.map((i, index) => renderInvoiceCard(i, index))}
          </Row>

          <div className="mt-3">
            {"Items per Page: "}
            <select
              onChange={(e) => handlePageSizeChange(e.target.value)}
              value={itemsPerPage}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingtotal={1}
              boundarytotal={1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
              size="medium"
            />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : resetMsg !== "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <div className="noResultsFound">{labels.NO_RESULTS}</div>
      )}
    </div>
  );
};

export default Invoices;
