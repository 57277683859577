import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getUsersList = (
  branch,
  type,
  keyword,
  offset,
  limit,
  clientsOnly,
  disabled,
  approved,
  oldSubscriber,
  source,
  start,
  end,
  subTypeFilter,
  planId,
  invFilter
) => {
  return axios.get(
    API_URL +
      "/admin/user/list?branch=" +
      branch +
      "&type=" +
      type +
      "&keyword=" +
      keyword +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&clientsOnly=" +
      clientsOnly +
      "&disabled=" +
      disabled +
      "&approved=" +
      approved +
      "&oldSubscriber=" +
      oldSubscriber +
      "&source=" +
      source +
      "&start=" +
      start +
      "&end=" +
      end +
      "&subTypeFilter=" +
      subTypeFilter +
      "&planId=" +
      planId +
      "&invFilter=" +
      invFilter,
    { headers: authHeader() }
  );
};

const exportUserHistory = () => {
  return axios.get(API_URL + "/investors/analytic", { headers: authHeader() });
};

const postUser = (data) => {
  return axios(API_URL + "/admin/user/save", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const sendForgetRequest = (email) => {
  return axios(API_URL + "/user/forgot", {
    method: "post",
    headers: authHeader(),
    data: {
      email: email,
    },
  });
};

const changePassword = (data) => {
  return axios(API_URL + "/user/changepass", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const disableEnable = (uid, disabled) => {
  return axios(API_URL + "/user/disable", {
    method: "post",
    headers: authHeader(),
    data: {
      uid: uid,
      disabled: disabled,
    },
  });
};

const sendNewPasswordRequest = (data) => {
  return axios(process.env.REACT_APP_SERVER_API + "/resetnewpassword", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const getUserById = (id) => {
  return axios.get(API_URL + "/admin/user/" + id, { headers: authHeader() });
};

const getUserDetailsById = (id) => {
  return axios.get(API_URL + "/admin/user/details/" + id, {
    headers: authHeader(),
  });
};

// This should be overwritten later. The delete should only mark the user as deleted.
const deleteUser = (id) => {
  return axios(API_URL + "/admin/user/delete/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const getUser = () => {
  return axios(API_URL + "/user/me?platform=1", {
    method: "get",
    headers: authHeader(),
  });
};

const verifyActivationCode = (id, code) => {
  return axios(API_URL + "/activate/" + id + "/" + code, {
    method: "get",
    headers: authHeader(),
  });
};

const resendCode = (email) => {
  return axios(API_URL + "/activate/resend", {
    method: "post",
    headers: authHeader(),
    data: { email },
  });
};

const userApproveReject = (data) => {
  return axios(API_URL + "/user/approveReject", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const rejectCardId = (data) => {
  return axios(API_URL + "/user/cardReject", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};
const signIagree = (data) => {
  return axios(API_URL + "/user/sign/Iagree", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};
const addTestimonial = (data) => {
  return axios(API_URL + "/user/testimonial", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const uploadCardId = (data) => {
  return axios(API_URL + "/user/cardId", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const updateProfile = (data) => {
  return axios(API_URL + "/user/update", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const updateInternalNote = (data) => {
  return axios(API_URL + "/user/note", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const getIagree = (id, regenerate) => {
  return axios.get(API_URL + `/iagree/request/${id}?regenerate=${regenerate}`, {
    headers: authHeader(),
  });
};

export default {
  postUser,
  getUserBoard,
  getUsersList,
  getUserDetailsById,
  getUserById,
  deleteUser,
  sendForgetRequest,
  sendNewPasswordRequest,
  disableEnable,
  changePassword,
  getUser,
  verifyActivationCode,
  resendCode,
  signIagree,
  getIagree,
  addTestimonial,
  userApproveReject,
  rejectCardId,
  uploadCardId,
  updateProfile,
  updateInternalNote,
  exportUserHistory,
};
