import React, { useState, useEffect, useRef } from "react";
import DateFromatter from "../common/DateFromatter";
import Currency from "./Currency";

export default function AccessoriesselectList(props) {
  let accessories = props.accessories;
  const disableField = props.disableField || false;
  const setAccessories = props.setAccessories;
  const setDescription = props.setDescription;
  const setVat = props.setVat;
  let vat = props.vat ? props.vat : 0;
  const setTotalAmount = props.setTotalAmount;
  const setAmount = props.setAmount;
  const inventoriesOptions = props.inventoriesOptions || "";
  const [vatChanged, setVatChanged] = useState([]);
  const [unitPriceVatChanged, setUnitPriceVatChanged] = useState([]);
  const showPeriod = props.showPeriod;
  const isSubscription = props.isSubscription;
  let totalPayment = props.totalPayment || 0;
  const daysPerMonth = 30;
  const endDate = props.endDate || "";
  const invoiceType = props.invoiceType || "";
  const setEndDate = props.setEndDate || "";
  const [latestDays, setLatestDays] = useState(30);
  const addAccessory = () => {
    setAccessories([
      ...accessories,
      {
        isNew: true,
        inventory: "",
        inventoryLabel: "",
        usage: "",
        quantity: "1",
        currency: "",
        unitPrice: "",
        totalPrice: "",
        sendAgain: true,
        days: latestDays,
        inventoryId: "",
      },
    ]);
  };

  const removeAccessory = (i) => {
    const list = [...accessories];
    list.splice(i, 1);
    setAccessories(list);
  };

  function updateTotalPayment(list) {
    totalPayment = 0;
    vat = 0;
    let amountNet = 0;

    for (var i = 0; i < list.length; i++) {
      totalPayment += Number(list[i]["totalPrice"]);
      amountNet += Number(
        list[i]["unitPrice"] *
          Number(list[i]["quantity"]) *
          (list[i]["days"] / daysPerMonth)
      );
      //vat = Number(list[i]['vat']);
    }

    if (!isNaN(totalPayment)) {
      setDescription("Accessories $" + totalPayment.toFixed(2));

      if (isSubscription == "false") {
        setAmount(amountNet);
        setTotalAmount(totalPayment);
        setVat(Math.round((totalPayment / amountNet - 1) * 100));
      }
    }
  }

  function updateAccessories(e, i) {
    const { name, value } = e.target;
    const list = [...accessories];
    list[i][name] = value;
    var id = list[i]["inventory"];

    if (id) {
      var selectedAccessory = inventoriesOptions.filter(
        (inv) => inv._id === id
      )[0];
      list[i]["currency"] = selectedAccessory.currency;
      list[i]["inventoryId"] = id;
      var usage = parseInt(list[i]["usage"]);
      var days = parseInt(list[i]["days"]);
      if (
        days == undefined ||
        days == 0 ||
        days == "undefined" ||
        days > daysPerMonth
      ) {
        days = daysPerMonth;
      }
      setLatestDays(days);
      for (let j = 0; j < list.length; j++) {
        list[j]["days"] = days;
      }

      var unitPrice = 0;
      if (usage === 0) {
        unitPrice = selectedAccessory.rentPrice;
      } else if (usage === 1) {
        unitPrice = selectedAccessory.sellPrice;
      }
      if (name === "days" && endDate && setEndDate) {
        let end = "";

        end = DateFromatter.addDays(new Date(), days);
        let d = end.split("-");
        setEndDate(new Date(d[0], d[1] - 1, d[2]));
      }
      if (name === "quantity") {
        if (value <= 0) {
          list[i]["quantity"] = 1;
        }
      }
      if (name === "VAT") {
        var l = vatChanged;
        l[i] = true;
        setVatChanged(l);
        list[i]["VAT"] = value;
      } else if (!vatChanged[i]) list[i]["VAT"] = selectedAccessory.VAT;

      if (name === "unitPriceVat") {
        var l = unitPriceVatChanged;
        l[i] = true;
        setUnitPriceVatChanged(l);
        list[i]["totalPrice"] = value * list[i]["quantity"];
      } else if (!unitPriceVatChanged[i]) list[i]["unitPriceVat"] = unitPrice;
      list[i]["unitPrice"] = Number(
        list[i]["unitPriceVat"] / (1 + list[i]["VAT"] / 100)
      ).toFixed(2);
      if (list[i]["quantity"] && list[i]["VAT"]) {
        list[i]["totalPrice"] =
          (list[i]["unitPriceVat"] * list[i]["quantity"] * days) / daysPerMonth;
      }

      if (
        list[i]["sendAgain"] == "" ||
        list[i]["sendAgain"] == undefined ||
        list[i]["sendAgain"] == "undefined"
      ) {
        list[i]["sendAgain"] = true;
      }

      list[i]["inventoryLabel"] = selectedAccessory.label;
    }

    setAccessories(list);
    if (invoiceType != "Surchage") {
      updateTotalPayment(list);
    }
  }
  console.log("==isSubscription", isSubscription);
  return (
    <>
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="accessories_subscription_th">
              <div>Accessories</div>
              <div>
                {!disableField ? (
                  <a
                    href="javascript:void(0)"
                    className="text-light"
                    onClick={addAccessory}
                  >
                    Add
                  </a>
                ) : (
                  ""
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {accessories &&
            accessories.length !== 0 &&
            accessories
              .filter(
                (acc) =>
                  // If it's not a subscription, show both "Rent" and "Buy"
                  !isSubscription ||
                  // If it is a subscription, only show "Rent" (exclude "Buy" and 1)
                  acc.usage === "0" ||
                  acc.usage === ""
              )
              .map((acc, index) => (
                <tr key={"acc" + index}>
                  <td className="accessories_big_grid">
                    <td className="accessories_subscription_td">
                      <div className="pr-2 form-group">
                        <label htmlFor="inventory">Item</label>
                        <select
                          className="form-control font-size14"
                          name="inventory"
                          value={
                            acc && acc.inventoryId
                              ? acc.inventoryId
                              : acc && acc.inventory
                              ? acc.inventory
                              : ""
                          }
                          disabled={disableField}
                          onChange={(e) => updateAccessories(e, index)}
                        >
                          <option value=""></option>
                          {inventoriesOptions &&
                            inventoriesOptions.length !== 0 &&
                            inventoriesOptions.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.number} - {item.label}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="pr-2 form-group">
                        <label htmlFor="usage">Usage</label>
                        <select
                          className="font-size14 form-control"
                          value={acc ? acc.usage : 0}
                          name="usage"
                          disabled={disableField}
                          onChange={(e) => updateAccessories(e, index)}
                        >
                          <option value=""></option>
                          <option value="0">Rent</option>
                          {!isSubscription && <option value="1">Buy</option>}
                        </select>
                      </div>
                      {acc.usage == "0" && showPeriod === true ? (
                        <div className="pr-2 form-group">
                          <label htmlFor="days">Days</label>
                          <input
                            type="number"
                            className="font-size14 form-control"
                            name="days"
                            value={acc ? acc.days : "30"}
                            onChange={(e) => updateAccessories(e, index)}
                            disabled={disableField}
                          />
                        </div>
                      ) : null}

                      <div className="pr-2 form-group">
                        <label htmlFor="quantity">Quantity</label>
                        <input
                          type="number"
                          className="font-size14 form-control"
                          name="quantity"
                          value={acc ? acc.quantity : ""}
                          onChange={(e) => updateAccessories(e, index)}
                          disabled={disableField}
                        />
                      </div>

                      <div className="pr-2 form-group">
                        <Currency
                          required
                          disableField={disableField}
                          index={index}
                          name="currency"
                          value={acc ? acc.currency : ""}
                          fn={updateAccessories}
                        />
                      </div>

                      <div className="pr-2 form-group">
                        <label htmlFor="unitPrice">
                          Unit Price{" "}
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: "lighter",
                              color: "gray",
                            }}
                          >
                            (Without VAT)
                          </span>
                        </label>
                        <input
                          type="number"
                          className="font-size14 form-control"
                          name="unitPrice"
                          value={acc ? acc.unitPrice : ""}
                          disabled
                        />
                      </div>

                      <div className="pr-2 form-group">
                        <label htmlFor="VAT">VAT</label>
                        <input
                          type="number"
                          className="font-size14 form-control"
                          name="VAT"
                          value={acc ? acc.VAT : 0}
                          onChange={(e) => updateAccessories(e, index)}
                          disabled={disableField}
                        />
                      </div>
                      <div className="pr-2 form-group">
                        <label htmlFor="unitPriceVat">
                          Unit Price{" "}
                          <span
                            style={{
                              fontSize: 11,
                              fontWeight: "lighter",
                              color: "gray",
                            }}
                          >
                            (With VAT)
                          </span>
                        </label>
                        <input
                          type="number"
                          className="font-size14 form-control"
                          name="unitPriceVat"
                          value={
                            acc
                              ? acc.unitPriceVat
                                ? acc.unitPriceVat
                                : acc.totalPrice / acc.quantity
                              : 0
                          }
                          onChange={(e) => updateAccessories(e, index)}
                          disabled={disableField}
                        />
                      </div>

                      <div className="pr-2 form-group">
                        <label htmlFor="totalPrice">Total Price</label>
                        <input
                          type="number"
                          className="font-size14 form-control"
                          name="totalPrice"
                          value={acc ? acc.totalPrice : ""}
                          disabled
                        />
                      </div>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      {disableField || acc.sendAgain === false ? (
                        ""
                      ) : (
                        <div className="accessory_icon_container">
                          <a
                            href="javascript:void(0)"
                            className="text-dark"
                            onClick={() => removeAccessory(index)}
                          >
                            <i
                              alt="Delete"
                              title="Delete"
                              className="fa fa-trash"
                            ></i>
                          </a>
                        </div>
                      )}
                    </td>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
}
