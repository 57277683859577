import React, { useState, useEffect } from "react";
import "../css/home.css";
import SinglePlan from "./SingelPlan";
import planService from "../../services/plan.service";

export default function PaymentPlans() {
  const [combinedPlans, setCombinedPlans] = useState([]);
  const plans = [
    {
      publicTitle: "The Basic Plan",
      subTitle:
        "1 month: $80 <br />2 months +: $65/month<br />4 months +: $49/month",
      includesDesc:
        "Bike <br /> Battery & Charger <br />  Helmet (free after 3 months) <br /> Maintenance at the hub <br /> Theft Insurance",
      planType: "monthly",
      period: "1",
    },
    {
      publicTitle: "The Student Plan",
      subTitle: "1 month: $45",
      includesDesc:
        "Bike <br /> Battery & Charger <br />  Helmet (free after 3 months) <br /> Maintenance at the hub <br /> Theft Insurance",
      remark: "*Student ID required",
      planType: "monthly",
      period: "1",
    },
    {
      publicTitle: "The Super Plan",
      subTitle: "1 month: $99 <br />4 months +: $69/month",
      includesDesc:
        "Basic plan <br />+<br /> Helmet for free <br /> Straps <br /> Road assistance within<br /> serviceable area <br />25% off broken parts cost",
      planType: "monthly",
      period: "1",
    },
    {
      publicTitle: "The Ultimate Plan",
      subTitle: " 1 month: $119 <br />2 months +: $89/month",
      includesDesc:
        "Super plan<br />+<br />Front Carrier Pouch<br />Rain Coat<br />Priority Support",
      titleColor: "#00BC70",
      planType: "monthly",
      period: "1",
    },
  ];

  useEffect(() => {
    async function onReady() {
      await fetchPlans();
    }
    window.scrollTo(0, 0);

    onReady();
  }, []);
  const fetchPlans = async () => {
    return await planService.getPublicPlans().then(({ data }) => {
      const systemPlans = data.data;
      const gatheredPlans = [];
      plans.map((plan, index) => {
        if (systemPlans[index]) {
          gatheredPlans.push(systemPlans[index]);
        } else {
          gatheredPlans.push(plan);
        }
      });
      setCombinedPlans(gatheredPlans);
    });
  };

  return (
    <>
      {/* payment plans on desktop */}
      <div id="why_wave_desktop_version" className="payment_plan_cards">
        {combinedPlans.map((plan, index) => {
          return (
            <SinglePlan
              index={index}
              planTitle={plan.publicTitle}
              titleColor={plan.titleColor}
              description={plan.subTitle}
              includesDescription={plan.includesDesc}
              remark={plan.remark}
              id={plan._id}
            />
          );
        })}
      </div>

      {/* payment plans on mobile */}
      <div id="why_wave_mobile_version" className="payment_plan_cards">
        {combinedPlans.map((plan, index) => {
          return (
            <SinglePlan
              index={index}
              planTitle={plan.publicTitle}
              titleColor={plan.titleColor}
              description={plan.subTitle}
              includesDescription={plan.includesDesc}
              remark={plan.remark}
              id={plan._id}
            />
          );
        })}
      </div>
    </>
  );
}
