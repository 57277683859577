import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getPlansList = (
  branch,
  planType,
  keyword,
  showPublic,
  offset,
  limit,
  onlyList
) => {
  return axios.get(
    API_URL +
      "/plan/list?branch=" +
      branch +
      "&planType=" +
      planType +
      "&keyword=" +
      keyword +
      "&showPublic=" +
      showPublic +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&onlyList=" +
      onlyList,
    { headers: authHeader() }
  );
};

const deletePlan = (id) => {
  return axios(API_URL + "/plan/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const postPlan = (
  id,
  label,
  disabled,
  period,
  price,
  branch,
  VAT,
  receiptPrice,
  currency,
  penalty,
  internal,
  chargersCount,
  batteriesCount,
  activateInvoiceSurcharge,
  planType,
  publicTitle,
  showPublic,
  subTitle,
  includesDesc,
  remark,
  orderonPublic,
  titleColor
) => {
  return axios(API_URL + "/plan/save", {
    method: "post",
    headers: authHeader(),
    data: {
      _id: id,
      label,
      disabled,
      period,
      price,
      branch,
      VAT,
      receiptPrice,
      currency,
      penalty,
      internal,
      chargersCount,
      batteriesCount,
      activateInvoiceSurcharge,
      planType,
      publicTitle,
      showPublic,
      subTitle,
      includesDesc,
      remark,
      orderonPublic,
      titleColor,
    },
  });
};

const getPlanById = (id) => {
  return axios.get(API_URL + "/plan/" + id, { headers: authHeader() });
};
const getPlanDetailsById = (id) => {
  return axios.get(API_URL + "/plan/details/" + id, { headers: authHeader() });
};
const getPublicPlans = () => {
  return axios.get(API_URL + "/public/plans");
};

export default {
  postPlan,
  deletePlan,
  getPlansList,
  getPlanById,
  getPlanDetailsById,
  getPublicPlans,
};
