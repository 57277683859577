export function toCamelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, "");
}

export function toPascalCase(str) {
  return str
    .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase()) // Convert hyphenated to PascalCase
    .replace(/^(.)/, (match, letter) => letter.toUpperCase()); // Capitalize the first letter
}

export function toCapitalizedCase(str) {
  return str
    .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase()) // Convert hyphenated to words with capital letters
    .replace(/^(.)/, (match, letter) => letter.toUpperCase()) // Capitalize the first letter of the string
    .replace(/([A-Z])/g, (match, letter, index) =>
      index > 0 ? letter.toLowerCase() : letter
    ); // Lowercase all the letters after the first
}
