import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";

import CheckButton from "react-validation/build/button";
import NewSelect from "react-select";
import { Prompt } from "react-router-dom";
import TimePicker from "../common/TimePicker";
import DateFormatter from "../common/DateFromatter";
import queryString from "query-string";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// -- customized

import SubscriptionService from "../services/subscription.service";
import EventBus from "../common/EventBus";
import RequiredLabel from "../common/RequiredLabel";
import { required } from "../common/Validation";
import commonFunctions from "../common/Functions";
import QuickUser from "../commonComponents/QuickUser";
import {
  getInventoriesOptions,
  getPlansList,
  getBikersList,
  getAvailableBats,
  getAvailableChargers,
  getAvailableBikes,
  getBranches,
} from "../common/getFunctions";
import DateFromatter from "../common/DateFromatter";
import OptionalLabel from "../common/OptionalLabel";

import AccessoriesSelectList from "../commonComponents/AccessoriesSelectList";

const SubscriptionForm = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const [startDate, setStartDate] = useState("");
  const [nextRenewal, setNextRenewal] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const subscribeId = props.match.params.id;
  const [bikerId, setBikerId] = useState("");
  const [biker, setBiker] = useState({});
  const [bikeId, setBikeId] = useState("");
  const [selectedBike, setSelectedBike] = useState({});
  const [selectedCharger, setSelectedCharger] = useState({});
  const [selectedCharger2, setSelectedCharger2] = useState({});
  const [selectedBat, setSelectedBat] = useState({});
  const [selectedBat2, setSelectedBat2] = useState({});
  const [batId, setBatId] = useState("");
  const [batId2, setBatId2] = useState("");
  const [chargersCount, setChargersCount] = useState(1);
  const [batteriesCount, setBatteriesCount] = useState(1);
  const [chargerId, setChargerId] = useState("");
  const [chargerId2, setChargerId2] = useState("");
  const [plan, setPlan] = useState("");
  const [internalPlan, setIsInternal] = useState(false);
  const [helmet, setHelmet] = useState("");
  const [bottle, setBottle] = useState("");
  const [bag, setBag] = useState("");
  const [helmetCheckbox, setHelmetCheckbox] = useState(false);
  const [bottleCheckbox, setBottleCheckbox] = useState(false);
  const [bagCheckbox, setBagCheckbox] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [startTime, setStartTime] = useState("09:00AM");
  const [isMonthly, setIsMonthly] = useState(true);
  const [selectedPlanType, setSelectedPlanType] = useState("monthly");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [accessories, setAccessories] = useState([]);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  // in new it should be blank, in edit, it is the intial coming from the api.
  //const [initialAccessories, setInitialAccessories] = useState([""]);

  // inventory pulldown options.
  const [inventoriesOptions, setInventoriesOptions] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [bikerList, setBikerList] = useState([]);
  const [chargerList, setChargerList] = useState([]);
  const [batList, setBatList] = useState([]);
  const [bottleList, setBottleList] = useState([]);
  const [helmetList, setHelmetList] = useState([]);
  const [bagList, setBagList] = useState([]);
  const [bikeList, setBikeList] = useState([]);
  const [alreadySaved, setAlreadySaved] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [note, setNote] = useState("");
  const [bikeChanged, setBikeChanged] = useState(false);
  const [batteryChanged, setBatteryChanged] = useState(false);
  const [chargerChanged, setChargerChanged] = useState(false);
  const [bikeLoaded, setBikeLoaded] = useState(true);
  const [batLoaded, setBatLoaded] = useState(true);
  const [chargerLoaded, setChargerLoaded] = useState(true);
  const [bikerLoaded, setBikerLoaded] = useState(true);
  const [iAmTyping, setIAmTyping] = useState(false);

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      let b = await getBranches();
      setBranches(b);
      setPlan(b[0].defaultPlan);

      await getSubscribeInfo();
    }

    onReady();
  }, []);

  function setBikerListWithDefault(list, id = bikerId) {
    let optionsList = [];
    list.map((l) => {
      if (l) {
        const option = {
          value: l._id,
          label: l.full_name,
        };
        optionsList.push(option);
        if (l._id == id) {
          setBiker(option);
        }
      }
    });
    setDataLoaded(true); // Mark data as loaded
    setBikerList(optionsList);
  }
  async function getSubscribeInfo() {
    if (subscribeId) {
      SubscriptionService.getSubscriptionById(subscribeId).then(
        async (response) => {
          let s = response.data;

          setBikerId(s.user);
          setBikerListWithDefault(
            await getBikersList(currentUser.branch),
            s.user
          );
          let availableBikes = commonFunctions.getOptionsList(
            await getAvailableBikes(s.branch, "", true)
          );

          if (s.bike) {
            const tempb = { value: s.bike._id, label: s.bike.number };
            availableBikes.push(tempb);
            setBikeList(availableBikes);
            setSelectedBike(s.bike);
            setBikeId(tempb); // set the bikeid as the entire object
          } else {
            setBikeList(availableBikes);
          }

          let availableBats = commonFunctions.getOptionsList(
            await getAvailableBats(s.branch, "", true)
          );
          if (s.battery) {
            const tempb = { value: s.battery._id, label: s.battery.number };
            availableBats.push(tempb);
            if (s.battery2) {
              const tempb2 = {
                value: s.battery2._id,
                label: s.battery2.number,
              };
              availableBats.push(tempb2);
              setBatId2(tempb2);
            }
            setBatList(availableBats);
            setSelectedBat(s.battery);
            setBatId(tempb);
          } else {
            setBatList(availableBats);
          }

          let availableChargers = commonFunctions.getOptionsList(
            await getAvailableChargers(s.branch, "", true)
          );
          if (s.charger) {
            const tempb = { value: s.charger._id, label: s.charger.number };
            availableChargers.push(tempb);
            if (s.charger2) {
              const tempb2 = {
                value: s.charger2._id,
                label: s.charger2.number,
              };
              availableChargers.push(tempb2);
              setChargerId2(tempb2);
            }
            setChargerList(availableChargers);
            setSelectedCharger(s.charger);
            setChargerId(tempb);
          } else {
            setChargerList(availableChargers);
          }

          setInventoriesOptions(
            await getInventoriesOptions(true, "", "", "", "", true)
          );
          let tempPlanList = await getPlansList(s.branch);
          setPlansList(tempPlanList);

          setBikerListWithDefault(await getBikersList(s.branch));
          setBottleList(
            await getInventoriesOptions(false, "Bottle", s.branch, "", "", true)
          );
          setHelmetList(
            await getInventoriesOptions(false, "Helmet", s.branch, "", "", true)
          );
          setBagList(
            await getInventoriesOptions(false, "Bag", s.branch, "", "", true)
          );

          setSelectedPlan(s.plan, tempPlanList);
          setHelmet(s.helmet);
          setBottle(s.bottle);
          setBag(s.bag);

          setHelmetCheckbox(s.helmet !== "" ? true : false);
          setBottleCheckbox(s.bottle ? true : false);
          setBagCheckbox(s.bag ? true : false);

          setAlreadySaved(!s.draft);
          setBranch(s.branch);

          setStartDate(new Date(s.start.split("T")[0]));

          setNextRenewal(
            s.nextRenewal != "None" ? new Date(s.nextRenewal) : ""
          );
          if (s.startTime) {
            setStartTime(s.startTime);
          }
          setAccessories(s.accessories);
          setInvoice(s.invoice);
          if (s.note) setNote(s.note);
          setBikerListWithDefault(await getBikersList(currentUser.branch));
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      setBikerListWithDefault(await getBikersList(currentUser.branch));
      setBatList(
        commonFunctions.getOptionsList(
          await getAvailableBats(currentUser.branch, "", true)
        )
      );
      setBikeList(
        commonFunctions.getOptionsList(
          await getAvailableBikes(currentUser.branch, "", true)
        )
      );
      setChargerList(
        commonFunctions.getOptionsList(
          await getAvailableChargers(currentUser.branch, "", true)
        )
      );
      setInventoriesOptions(
        await getInventoriesOptions(true, "", currentUser.branch, "", "", true)
      );

      var tempPlanList = await getPlansList(currentUser.branch);
      setPlansList(tempPlanList);
      if (tempPlanList.length > 0 && tempPlanList[0].branch)
        setSelectedPlan(tempPlanList[0].branch.defaultPlan, tempPlanList);

      // setBikerListWithDefault(await getBikersList());
      setBottleList(
        await getInventoriesOptions(
          false,
          "Bottle",
          currentUser.branch,
          "",
          "",
          true
        )
      );
      setHelmetList(
        await getInventoriesOptions(
          false,
          "Helmet",
          currentUser.branch,
          "",
          "",
          true
        )
      );
      setBagList(
        await getInventoriesOptions(
          false,
          "Bag",
          "",
          currentUser.branch,
          "",
          true
        )
      );
      var timeInterval = DateFromatter.getStartEndDate(30);
      setStartDate(new Date(timeInterval.start));
      setBranch(currentUser.branch);

      let queries = queryString.parse(props.location.search);
      if (queries.branch && queries.user) {
        setBranch(queries.branch);
        // get users according to the selected branchy
        setBikerListWithDefault(
          await getBikersList(queries.branch),
          queries.user
        );
        setBikerId(queries.user);
        setPlan(queries.planId);
      }
    }
  }

  const handleSaveSubscription = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setSuccessful(false);

    form.current.validateAll();

    // this is a temporary code till we check if bottle and bags are a must.
    //if (!bottleCheckbox || !bagCheckbox) {
    //	alert("Please make sure to select a Bag and Bottle")
    //	return false;
    //}
    if (chargersCount == 2 && chargerId2 == "") {
      alert("Please make sure to select 2nd charger.");
      return false;
    }
    if (chargerId2 != "" && chargerId2 == chargerId) {
      alert("Please make sure to select different chargers.");
      return false;
    }
    if (batteriesCount == 2 && batId2 == "") {
      alert("Please make sure to select 2nd battery.");
      return false;
    }
    if (batId2 != "" && batId2 == batId) {
      alert("Please make sure to select different batteries.");
      return false;
    }

    if (checkBtn.current.context._errors.length === 0) {
      var accessoriesFinal = [];
      accessories.map((x, i) => {
        if (x !== "") {
          accessoriesFinal.push(x);
        }
      });

      var rentedAcc = "";
      accessoriesFinal.map((a, i) => {
        var selectedAccessory = inventoriesOptions.filter(
          (inv) => inv._id === a.inventory
        )[0];
        if (a.usage === "0")
          rentedAcc = rentedAcc + selectedAccessory.label + ", ";
      });
      rentedAcc = rentedAcc.slice(0, rentedAcc.length - 2);

      //console.log("Rented Acc: ", rentedAcc);

      var draft = false;

      if (e.target.value === "draft") draft = true;

      let data = {
        _id: subscribeId,
        plan: plan,
        user: bikerId,
        bike: bikeId ? bikeId.value : "",
        start: startDate,
        startTime: startTime,
        nextRenewal: nextRenewal
          ? nextRenewal.getFullYear() +
            "-" +
            DateFormatter.padTo2Digits(nextRenewal.getMonth() + 1) +
            "-" +
            DateFormatter.padTo2Digits(nextRenewal.getDate())
          : null,
        chargerId: chargerId ? chargerId.value : "",
        batteryId: batId ? batId.value : "",
        helmetId: helmet,
        bagId: bag,
        bottleId: bottle,
        draft: draft,
        branch: branch,
        accessories: commonFunctions.removeDuplicates(accessoriesFinal),
        note: note,
        rentedAcc: rentedAcc,
        chargerId2: chargerId2 ? chargerId2.value : "",
        batId2: batId2 ? batId2.value : "",
      };

      //console.log(data);
      SubscriptionService.postSubscription(data).then(
        (response) => {
          setMessage("Record is saved.");
          setSuccessful(true);

          if (
            (bikeChanged || batteryChanged || chargerChanged) &&
            response &&
            response.data &&
            response.data.data &&
            response.data.data.maintenanceId
          )
            window.location.href =
              window.location.protocol +
              "//" +
              window.location.host +
              "/maintenance/edit/" +
              response.data.data.maintenanceId;
          else if (
            !internalPlan &&
            !subscribeId &&
            isMonthly &&
            response &&
            response.data &&
            response.data.data &&
            response.data.data.subscribeId
          ) {
            SubscriptionService.createInvoice(
              response.data.data.subscribeId,
              false
            ).then((res) => {
              alert("A draft invoice is created for this subscription!");

              if (res.data && res.data.data) {
                props.history.push(
                  "/invoice/edit/" + res.data.data + "?subscription"
                );
              }
            });
          } else if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.invoiceId
          ) {
            alert("A draft invoice for accessories has been created.");
            props.history.push("/invoice/edit/" + response.data.data.invoiceId);
          } else props.history.push("/subscriptions");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const handleBranchChange = async (bid) => {
    if (bid != "") {
      setBranch(bid);
      // in this case we need to get the new list of bikers.
      setBikerListWithDefault(await getBikersList(bid));
      var tempPlanList = await getPlansList(bid);
      setPlansList(tempPlanList);
      if (tempPlanList.length > 0 && tempPlanList[0].branch)
        setPlan(tempPlanList[0].branch.defaultPlan);

      setBikeList(
        commonFunctions.getOptionsList(await getAvailableBikes(bid, "", true))
      );
      setBatList(
        commonFunctions.getOptionsList(await getAvailableBats(bid, "", true))
      );
      setChargerList(
        commonFunctions.getOptionsList(
          await getAvailableChargers(bid, "", true)
        )
      );
      setInventoriesOptions(
        await getInventoriesOptions(true, "", bid, "", "", true)
      );
      setBottleList(
        await getInventoriesOptions(false, "Bottle", bid, "", "", true)
      );
      setHelmetList(
        await getInventoriesOptions(false, "Helmet", bid, "", "", true)
      );
      setBagList(await getInventoriesOptions(false, "Bag", bid, "", "", true));
    }
  };

  /*
		const helmetCheckboxHandler = (event) => {
	
			var isChecked = event.target.checked;
			setHelmetCheckbox(!helmetCheckbox);
	
			if (isChecked) {
				if (helmetList && helmetList.length > 0)
					setHelmet(helmetList[0]._id)
				else {
					alert("There is no Helments, Please Add Helmet");
					setHelmetCheckbox(false);
				}
			}
			else {
				setHelmet("");
			}
	
		}
	*/
  const setSelectedPlan = async (id, plansList) => {
    setPlan(id);
    let selectedplanT = plansList.filter((plan) => plan._id === id)[0];
    if (selectedplanT) {
      setChargersCount(selectedplanT.chargersCount || 1);
      setBatteriesCount(selectedplanT.batteriesCount || 1);
      setIsInternal(selectedplanT ? selectedplanT.internal : "");
      if (selectedplanT.chargersCount != 2) {
        setChargerId2("");
      }
      if (selectedplanT.batteriesCount != 2) {
        setBatId2("");
      }

      const selectedplanType = selectedplanT.planType || "monthly";
      setSelectedPlanType(selectedplanType);
      setIsMonthly(selectedplanType == "monthly");
    }
  };

  const bottleCheckboxHandler = (isChecked) => {
    setBottleCheckbox(isChecked);

    if (isChecked) {
      if (bottleList.length > 0) {
        for (let element of bottleList) {
          if (element.total_available > 0) {
            setBottle(element._id);
            break;
          }
        }
      } else {
        alert("There is no bottles in the inventory! Please add bottle");
        setBottleCheckbox(false);
      }
    } else {
      setBottle("");
    }
  };

  const bagCheckboxHandler = (isChecked) => {
    setBagCheckbox(isChecked);
    if (isChecked) {
      if (bagList.length > 0) {
        for (let element of bagList) {
          if (element.total_in_stock > 0) {
            setBag(element._id);
            break;
          }
        }
      } else if (!bag) {
        alert("There is no bags in the inventory! Please add bag!");
        setBagCheckbox(false);
      }
    } else {
      setBag("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setNote(note + "\n");
    }
  };

  const handleBikeChange = (bid) => {
    if (bikeId && bikeId !== bid) setBikeChanged(true);
    setBikeId(bid);
  };

  const handleBatChange = (bid) => {
    if (batId && batId !== bid) setBatteryChanged(true);
    setBatId(bid);
  };

  const handleBatChange2 = (bid) => {
    if (batId2 && batId2 !== bid) setBatteryChanged(true);
    setBatId2(bid);
  };

  const handleChargerChange = (bid) => {
    if (chargerId && chargerId !== bid) setChargerChanged(true);
    setChargerId(bid);
  };

  const handleChargerChange2 = (cid) => {
    if (chargerId2 && chargerId2 !== cid) setChargerChanged(true);
    setChargerId2(cid);
  };
  const loadNewUserPopup = async (e) => {
    e.preventDefault();
    setOpenUserModal(true);
  };

  const refreshBikersList = async (e) => {
    e.preventDefault();
    setBikerLoaded(false);
    setBikerListWithDefault(await getBikersList(branch));
    setBikerLoaded(true);
  };
  const hiddenRefresh = async () => {
    setBikerListWithDefault(await getBikersList(branch));
  };
  const refreshBikeList = async (e) => {
    e.preventDefault();
    setBikeLoaded(false);
    if (selectedBike && selectedBike._id) {
      setBikeList([
        ...commonFunctions.getOptionsList(
          await getAvailableBikes(branch, "", true)
        ),
        { value: selectedBike._id, label: selectedBike.number },
      ]);
    } else {
      setBikeList(
        commonFunctions.getOptionsList(
          await getAvailableBikes(branch, "", true)
        )
      );
    }

    setBikeLoaded(true);
  };
  const refreshBatList = async (e) => {
    e.preventDefault();
    setBatLoaded(false);
    if (selectedBat && selectedBat._id) {
      let temp = commonFunctions.getOptionsList(
        await getAvailableBats(branch, "", true)
      );
      temp.push({ value: selectedBat._id, label: selectedBat.number });
      if (selectedBat2 && selectedBat2._id) {
        temp.push({ value: selectedBat2._id, label: selectedBat2.number });
      }
      setBatList(temp);
    } else {
      setBatList(
        commonFunctions.getOptionsList(await getAvailableBats(branch, "", true))
      );
    }

    setBatLoaded(true);
  };

  const refreshChargerList = async (e) => {
    e.preventDefault();
    setChargerLoaded(false);
    if (selectedCharger && selectedCharger._id) {
      let temp = commonFunctions.getOptionsList(
        await getAvailableChargers(branch, "", true)
      );
      temp.push({ value: selectedCharger._id, label: selectedCharger.number });
      if (selectedCharger2 && selectedCharger2._id) {
        temp.push({
          value: selectedCharger2._id,
          label: selectedCharger2.number,
        });
      }
      setChargerList(temp);
    } else {
      setChargerList(
        commonFunctions.getOptionsList(
          await getAvailableChargers(branch, "", true)
        )
      );
    }
    setChargerLoaded(true);
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <QuickUser
        setOpenUserModal={setOpenUserModal}
        openUserModal={openUserModal}
        branch={branch}
        callbackFn={hiddenRefresh}
      />
      <div className="col-md-12 font-20">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table
                className="styled-table"
                style={{ width: "100%", margin: "auto" }}
                cellPadding="10"
              >
                <thead>
                  <tr>
                    <th>Subscription Form</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td className="grid_container_4">
                      {currentUser.type === "admin" ? (
                        <div className="form-group">
                          <label htmlFor="branch">Branch</label>
                          <Select
                            className="form-control"
                            value={branch}
                            onChange={(e) => {
                              handleBranchChange(e.target.value);
                              setIAmTyping(true);
                            }}
                            validations={[required]}
                            disabled={invoice ? invoice.sent : null}
                          >
                            <option value=""></option>
                            {branches &&
                              branches.map((m, index) => (
                                <option key={index} value={m._id}>
                                  {m.label}
                                </option>
                              ))}
                          </Select>
                        </div>
                      ) : null}

                      <div className="form-group">
                        <RequiredLabel htmlFor="plan" text="Plan" />
                        <Select
                          name="plan"
                          className="form-control"
                          value={plan}
                          onChange={(e) => {
                            setSelectedPlan(e.target.value, plansList);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        >
                          <option value=""></option>
                          {plansList && plansList.length > 0
                            ? plansList.map((plan, index) => (
                                <option key={index} value={plan._id}>
                                  {plan.label}
                                </option>
                              ))
                            : null}
                        </Select>
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="bikerId"
                          text="Client Name"
                          showRefresh={true}
                          onclickAction={(e) => refreshBikersList(e)}
                          loaded={bikerLoaded}
                          showNew={true}
                          onNewClick={(e) => loadNewUserPopup(e)}
                        />
                        <NewSelect
                          value={biker}
                          onChange={(selectedClient) => {
                            setBiker(selectedClient);
                            setBikerId(selectedClient?.value);
                            setIAmTyping(true);
                          }}
                          options={bikerList}
                          validations={[required]}
                          isDisabled={invoice ? invoice.sent : false}
                          isClearable
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel htmlFor="startDate" text="Start Date" />

                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          name="startDate"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setIAmTyping(true);
                          }}
                          //disabled={invoice ? invoice.sent : null}
                        />
                      </div>
                      {selectedPlanType == "daily" ||
                      selectedPlanType == "hourly" ? (
                        <div>
                          <RequiredLabel
                            htmlFor="startTime"
                            text="Start Time"
                          />
                          <TimePicker time={startTime} setTime={setStartTime} />
                        </div>
                      ) : (
                        ""
                      )}
                      {subscribeId ? (
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="nextRenewal"
                            text="Next Renewal"
                          />

                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            name="nextRenewal"
                            selected={nextRenewal}
                            onChange={(date) => {
                              setNextRenewal(date);
                              setIAmTyping(true);
                            }}
                            //disabled={invoice ? invoice.sent : null}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="bikeId"
                          text="Bike #"
                          showRefresh={true}
                          onclickAction={(e) => refreshBikeList(e)}
                          loaded={bikeLoaded}
                        />

                        <NewSelect
                          value={bikeId}
                          onChange={(selectedB) => {
                            handleBikeChange(selectedB);
                            setIAmTyping(true);
                          }}
                          options={bikeList}
                          isClearable
                        />
                      </div>
                      <div className="form-group">
                        <RequiredLabel
                          htmlFor="batId"
                          text="Battery #"
                          showRefresh={true}
                          onclickAction={(e) => refreshBatList(e)}
                          loaded={batLoaded}
                        />

                        <NewSelect
                          value={batId}
                          onChange={(selectedBat) => {
                            handleBatChange(selectedBat);
                            setIAmTyping(true);
                          }}
                          options={batList}
                          isClearable
                        />
                      </div>
                      {batteriesCount > 1 ? (
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="batId2"
                            text="Battery 2 #"
                            showRefresh={true}
                            onclickAction={(e) => refreshBatList(e)}
                            loaded={batLoaded}
                          />
                          <NewSelect
                            value={batId2}
                            onChange={(selectedBat2) => {
                              handleBatChange2(selectedBat2);
                              setIAmTyping(true);
                            }}
                            options={batList}
                            isClearable
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group">
                        {isMonthly ? (
                          <RequiredLabel
                            htmlFor="chargerId"
                            text="Charger #"
                            showRefresh={true}
                            onclickAction={(e) => refreshChargerList(e)}
                            loaded={chargerLoaded}
                          />
                        ) : (
                          <OptionalLabel
                            htmlFor="chargerId"
                            text="Charger #"
                            showRefresh={true}
                            onclickAction={(e) => refreshChargerList(e)}
                            loaded={chargerLoaded}
                          />
                        )}

                        <NewSelect
                          value={chargerId}
                          onChange={(selectedCharger) => {
                            handleChargerChange(selectedCharger);
                            setIAmTyping(true);
                          }}
                          options={chargerList}
                          isClearable
                        />
                      </div>

                      {chargersCount > 1 ? (
                        <div className="form-group">
                          <RequiredLabel
                            htmlFor="chargerId"
                            text="Charger 2 #"
                            showRefresh={true}
                            onclickAction={(e) => refreshChargerList(e)}
                            loaded={chargerLoaded}
                          />
                          <NewSelect
                            value={chargerId2}
                            onChange={(selectedCharger2) => {
                              handleChargerChange2(selectedCharger2);
                              setIAmTyping(true);
                            }}
                            options={chargerList}
                            isClearable
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group">
                        <OptionalLabel
                          htmlFor="helmet_checkbox"
                          text="Helmet"
                        />
                        <Select
                          className="form-control"
                          value={helmet}
                          onChange={(e) => {
                            setHelmet(e.target.value);
                            setIAmTyping(true);
                          }}
                        >
                          <option value="">No Helmet</option>
                          {helmetList && helmetList.length > 0
                            ? helmetList.map((h, index) => (
                                <option key={index} value={h._id}>
                                  {h.number}- {h.label}
                                </option>
                              ))
                            : ""}
                        </Select>
                      </div>
                      {isMonthly ? (
                        <>
                          <div className="form-group text-center">
                            <OptionalLabel
                              htmlFor="bottle_checkbox"
                              text="Bottle"
                            />
                            <Switch
                              checked={bottleCheckbox}
                              onChange={(event) => {
                                bottleCheckboxHandler(event);
                                setIAmTyping(true);
                              }}
                            />
                          </div>

                          <div className="form-group text-center">
                            <OptionalLabel htmlFor="bag_checkbox" text="Bag" />
                            <Switch
                              checked={bagCheckbox}
                              onChange={(event) => {
                                bagCheckboxHandler(event);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <OptionalLabel htmlFor="notes" text="Notes" />
                        <textarea
                          className="form-control"
                          name="notes"
                          autoComplete="off"
                          onKeyDown={(e) => handleKeyDown(e)}
                          onChange={(e) => {
                            setNote(e.target.value);
                            setIAmTyping(true);
                          }}
                          cols="30"
                          rows="4"
                          value={note}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <AccessoriesSelectList
                showPeriod={false}
                accessories={accessories}
                inventoriesOptions={inventoriesOptions}
                setAccessories={setAccessories}
                setDescription={setNote}
                isSubscription={true}
                disableField={false}
              />

              {alreadySaved ? (
                <button
                  onClick={(e) => handleSaveSubscription(e)}
                  value="save"
                  className="btn btn-primary btn-block"
                >
                  Save
                </button>
              ) : (
                <div className="save_draft_buttons_container">
                  <div className="button_half_width">
                    <button
                      onClick={(e) => handleSaveSubscription(e)}
                      value="draft"
                      className="btn btn-primary btn-block"
                    >
                      Save as Draft
                    </button>
                  </div>

                  <div className="button_half_width">
                    <button
                      onClick={(e) => handleSaveSubscription(e)}
                      value="save"
                      className="btn btn-primary btn-block"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default SubscriptionForm;
