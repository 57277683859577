import React from "react";
import "./ProfileTabs.css";

const ProfileTabs = ({ activeTab, setActiveTab, currentUser, type }) => (
  <nav className="profile-tabs">
    <ul>
      <li
        className={activeTab === "overview" ? "active" : ""}
        onClick={() => setActiveTab("overview")}
      >
        Overview
      </li>
      {(currentUser.type === "admin" || type != "admin") && (
        <li
          className={activeTab === "identiy" ? "active" : ""}
          onClick={() => setActiveTab("identiy")}
        >
          Identiy Cards
        </li>
      )}
      <li
        className={activeTab === "invoices" ? "active" : ""}
        onClick={() => setActiveTab("invoices")}
      >
        Payments
      </li>
      <li
        className={activeTab === "subscriptions" ? "active" : ""}
        onClick={() => setActiveTab("subscriptions")}
      >
        Subscriptions
      </li>
      <li
        className={activeTab === "journeys" ? "active" : ""}
        onClick={() => setActiveTab("journeys")}
      >
        Journeys
      </li>
      <li
        className={activeTab === "maintenances" ? "active" : ""}
        onClick={() => setActiveTab("maintenances")}
      >
        Maintenances
      </li>
      <li
        className={activeTab === "logs" ? "active" : ""}
        onClick={() => setActiveTab("logs")}
      >
        Logs
      </li>
    </ul>
  </nav>
);

export default ProfileTabs;
