import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import CheckButton from "react-validation/build/button";
import BikeService from "../services/bike.service";
import EventBus from "../common/EventBus";
import {
  getBranches,
  getInventoriesOptions,
  getModels,
} from "../common/getFunctions";
import { required } from "../common/Validation";
import * as options from "../labels/options_en";
import { Prompt } from "react-router-dom";

const BikeForm = (props) => {
  const form = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const checkBtn = useRef();
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [model, setModel] = useState("");
  const [kilometers, setKilometers] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Ready");
  const [models, setModels] = useState([]);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [bikeId, setBikeId] = useState(props.match.params.id);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [iAmTyping, setIAmTyping] = useState(false);
  const [pullFromModel, setPullFromModel] = useState("no");
  const [inventoriesOptions, setInventoriesOptions] = useState([]);
  const [removedInventories, setRemovedInventories] = useState([]);
  const [inventory, setInventory] = useState({});
  const clone = props.location.pathname.includes("/clone/");

  const setInventoryDetails = (id) => {
    var selectedInventory = inventoriesOptions.filter(
      (inv) => inv._id === id
    )[0];
    setRemovedInventories([...removedInventories, selectedInventory]);
    setInventory("");
  };

  const removeInventory = (i) => {
    const list = [...removedInventories];
    list.splice(i, 1);
    setRemovedInventories(list);
  };
  const getBikeInfo = async () => {
    if (bikeId) {
      BikeService.getBikeById(bikeId).then(
        async (response) => {
          let b = response.data;
          setModels(await getModels("", b.branch, "", "", true, "bike"));
          setNumber(b.number);
          setModel(b.model);
          setInventoriesOptions(
            await getInventoriesOptions(
              "",
              "",
              b.branch,
              "",
              "",
              true,
              "",
              b.model
            )
          );
          setKilometers(b.kilometers);
          setDescription(b.description);
          setStatus(b.status);
          setBranch(b.branch);
          setRemovedInventories(b.removedInventories);
          if (clone === true) {
            setBikeId("");
            setStatus("Ready");
          } // reset the id to clone
          setLoaded(true);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      setModels(await getModels("", "", "", "", true, "bike"));
      setLoaded(true);
    }
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      getBikeInfo();
    }

    onReady();
  }, loaded);

  const handleSaveBike = (e) => {
    e.preventDefault();
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);

    form.current.validateAll();
    var removedparts = removedInventories
      ? removedInventories.map((object) => object._id)
      : [];
    if (checkBtn.current.context._errors.length === 0) {
      BikeService.postBike(
        bikeId,
        number,
        model,
        kilometers,
        status,
        description,
        branch,
        pullFromModel,
        removedparts
      ).then(
        (response) => {
          setMessage("Bike saved.");
          setSuccessful(true);
          props.history.push("/bikes");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const handleBranchChange = async (bid) => {
    setBranch(bid);
    setModels(await getModels("", bid, "", "", true, "bike"));
  };
  const triggerSetModel = async (mid) => {
    setModel(mid);
    setInventoriesOptions(
      await getInventoriesOptions("", "", branch, "", "", true, "", mid)
    );
  };
  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Bike Specification</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    {currentUser.type === "admin" ? (
                      <td>
                        <div className="form-group">
                          <label htmlFor="branch">Branch</label>
                          <Select
                            className="form-control"
                            value={branch}
                            onChange={(e) => {
                              handleBranchChange(e.target.value);
                              setIAmTyping(true);
                            }}
                            validations={[required]}
                          >
                            <option value=""></option>
                            {branches &&
                              branches.map((m) => (
                                <option value={m._id}>{m.label}</option>
                              ))}
                          </Select>
                        </div>
                      </td>
                    ) : null}

                    <td>
                      <div className="form-group">
                        <label htmlFor="pullFromModel">Pull From Model</label>
                        <select
                          className="form-control"
                          value={pullFromModel}
                          onChange={(e) => setPullFromModel(e.target.value)}
                        >
                          <option key="yes_pull" value="yes">
                            Yes
                          </option>
                          <option key="no_pull" value="no">
                            No
                          </option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr className="white-background">
                    <td>
                      <div className="form-group">
                        <label htmlFor="number">Number</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="number"
                          value={number}
                          onChange={(e) => {
                            setNumber(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label htmlFor="model">Model</label>

                        <Select
                          className="form-control"
                          value={model}
                          onChange={(e) => {
                            triggerSetModel(e.target.value);
                            setIAmTyping(true);
                          }}
                          validations={[required]}
                        >
                          <option value=""></option>
                          {models &&
                            models.map((m) => (
                              <option value={m._id}>{m.label}</option>
                            ))}
                        </Select>
                      </div>
                    </td>
                  </tr>

                  <tr className="white-background">
                    <td>
                      <div className="form-group">
                        <label htmlFor="kilometers">Odometer</label>
                        <Input
                          type="number"
                          className="form-control"
                          name="kilometers"
                          value={kilometers}
                          onChange={(e) => {
                            setKilometers(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <label htmlFor="status">Status</label>
                        {status == "In Use" ||
                        status == "Ready" ||
                        status == "Stolen" ? (
                          <>
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              {options.bike_edit_status.map((item, index) => (
                                <option
                                  key={"bike_status_" + index}
                                  value={item.key}
                                >
                                  {item.value}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          status
                        )}
                      </div>
                    </td>
                  </tr>

                  <tr className="white-background">
                    <td colSpan="2">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <Textarea
                          type="text"
                          className="form-control"
                          name="description"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                            setIAmTyping(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th colspan={2}>Removed Inventories</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan={2}>
                      <Select
                        className="form-control"
                        name="inventory"
                        value={inventory._id || ""}
                        onChange={(e) => {
                          setInventoryDetails(e.target.value);
                        }}
                      >
                        <option value=""></option>
                        {inventoriesOptions.map((item, index) => (
                          <option value={item._id}>
                            {item.number} - {item.label}
                          </option>
                        ))}
                      </Select>
                    </td>
                  </tr>
                  {removedInventories &&
                    removedInventories.map(function (inv, index) {
                      if (inv) {
                        return (
                          <tr key={index}>
                            <td>
                              {inv.number} - {inv.label} - {inv.type}
                            </td>
                            <td>
                              <a
                                className="mr10"
                                href="javascript:void(0)"
                                onClick={() => removeInventory(index)}
                              >
                                Remove
                              </a>
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>

              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSaveBike}
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
};

export default BikeForm;
