import React, { useState, useEffect } from "react";
import InvoiceService from "../services/invoice.service";
import { viewPDF } from "../common/pdf";
import Loader from "../commonComponents/Loader";
import { getInvoicesList } from "../common/getFunctions";
import DateFormatter from "../common/DateFromatter";
import "./ClientPayments.css";

const currentUser = JSON.parse(localStorage.getItem("user")) || null;

const ClientPayments = () => {
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [activeTab, setActiveTab] = useState("paid");

  // Fetch invoices based on active tab (paid or unpaid)
  const fetchInvoices = async (paidStatus) => {
    setLoading(true);

    try {
      const response = await getInvoicesList(
        currentUser._id,
        "",
        currentUser.branch,
        "",
        "",
        (page - 1) * itemsPerPage,
        itemsPerPage,
        "",
        "",
        "",
        paidStatus,
        "",
        "",
        "",
        "",
        ""
      );

      if (response && response.list) {
        setTotal(response.total || 0);
        if (paidStatus === "yes") {
          setPaidInvoices((prevInvoices) => [
            ...prevInvoices,
            ...response.list,
          ]);
        } else {
          setUnpaidInvoices((prevInvoices) => [
            ...prevInvoices,
            ...response.list,
          ]);
        }
      }
    } catch (err) {
      setError("Failed to fetch invoices.");
    } finally {
      setLoading(false);
    }
  };

  // Pay invoice
  const payInvoice = async (invoiceId) => {
    try {
      // Set the returnUrl to a success page or the current page
      const returnUrl = window.location.href;

      // Get the payment URL from the service
      const paymentURLResponse = await InvoiceService.createCheckoutURL({
        paymenttype: "moolie",
        invoiceId: invoiceId,
        returnUrl: returnUrl,
      });

      // If the payment URL is available, redirect user to it
      if (paymentURLResponse.data.checkouturl) {
        window.location.href = paymentURLResponse.data.checkouturl;
      } else {
        setError("Payment URL is not available.");
      }
    } catch (err) {
      setError("Failed to pay invoice.");
      console.error(err); // Log the error for debugging
    }
  };

  // Load more invoices when clicking "Show More"
  const loadMoreInvoices = (paidStatus) => {
    setPage((prevPage) => prevPage + 1);
    fetchInvoices(paidStatus);
  };

  // Fetch invoices when the active tab or page changes
  useEffect(() => {
    setPage(1); // Reset to page 1 when switching tabs
    if (activeTab === "paid") {
      setPaidInvoices([]); // Clear previous invoices before fetching new ones
      fetchInvoices("yes"); // Fetch Paid Invoices
    } else {
      setUnpaidInvoices([]); // Clear previous invoices before fetching new ones
      fetchInvoices("no"); // Fetch Unpaid Invoices
    }
  }, [activeTab, page]); // Trigger fetching when activeTab or page changes

  // Render invoices list
  const renderInvoices = (invoices) => {
    return invoices.map((invoice) => (
      <div key={invoice._id} className="timeline-item">
        <div className="timeline-content">
          <h5>{invoice.description}</h5>
          <p>
            Amount: {invoice.total_amount}
            {invoice.currency.toLowerCase() === "euro"
              ? "€"
              : invoice.currency.toLowerCase() === "usd"
              ? "$"
              : invoice.currency.toLowerCase() === "lbp"
              ? "L.L"
              : invoice.currency}
          </p>
          <p>
            {invoice.paid ? "Payment Date: " : "Due Date: "}
            {invoice.paid
              ? DateFormatter.DateFormatterOne(invoice.paymentDate)
              : DateFormatter.DateFormatterOne(invoice.start)}
          </p>

          <p>
            Status: {invoice.paid ? "Paid" : "Unpaid"}
            {/* Pay Invoice Link for Unpaid Invoices */}
            {!invoice.paid && invoice.currency.toLowerCase() === "euro" && (
              <a
                href="javascript:void(0)"
                onClick={() => payInvoice(invoice._id)}
                className="pay-invoice-link"
              >
                Pay Invoice
              </a>
            )}
          </p>

          {/* PDF Icon Link placed under the invoice title, only for paid invoices */}
          {invoice.paid && (
            <div className="pdf-container">
              <a
                href="javascript:void(0)"
                onClick={() =>
                  viewPDF(invoice._id, invoice.sent ? invoice.fileName : "")
                }
                className="text-dark midText"
              >
                <i
                  alt="PDF"
                  title="Download PDF"
                  className="fa fa-file-pdf"
                ></i>
              </a>
            </div>
          )}
        </div>
      </div>
    ));
  };

  // Hide "Show More" if there are no more invoices to load
  const showMorePaidInvoices =
    paidInvoices.length < total && paidInvoices.length > 0;
  const showMoreUnpaidInvoices =
    unpaidInvoices.length < total && unpaidInvoices.length > 0;

  return (
    <div id="client-payments-page" className="container">
      {/* Tabs */}
      <div className="tabs">
        <div
          className={`tab ${activeTab === "paid" ? "active" : ""}`}
          onClick={() => setActiveTab("paid")}
        >
          Paid Invoices
        </div>
        <div
          className={`tab ${activeTab === "unpaid" ? "active" : ""}`}
          onClick={() => setActiveTab("unpaid")}
        >
          Unpaid Invoices
        </div>
      </div>

      {/* Actions for Paid and Unpaid invoices */}
      {activeTab === "paid" && (
        <div>
          {paidInvoices.length > 0 ? (
            renderInvoices(paidInvoices)
          ) : (
            <div>No paid invoices</div>
          )}
          {!loading && showMorePaidInvoices && (
            <button
              onClick={() => loadMoreInvoices("yes")}
              className="show-more-btn"
            >
              Show More Paid Invoices
            </button>
          )}
        </div>
      )}

      {activeTab === "unpaid" && (
        <div>
          {unpaidInvoices.length > 0 ? (
            renderInvoices(unpaidInvoices)
          ) : (
            <div>No unpaid invoices</div>
          )}
          {!loading && showMoreUnpaidInvoices && (
            <button
              onClick={() => loadMoreInvoices("no")}
              className="show-more-btn"
            >
              Show More Unpaid Invoices
            </button>
          )}
        </div>
      )}

      {/* Loading Spinner */}
      {loading && <Loader />}

      {/* Error Message */}
      {error && (
        <header className="jumbotron">
          <h3>{error}</h3>
        </header>
      )}
    </div>
  );
};

export default ClientPayments;
