import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const deleteInvoice = (id) => {
  return axios(API_URL + `/invoice/${id}`, {
    method: "delete",
    headers: authHeader(),
  });
};

const postInvoice = (invoice) => {
  return axios(API_URL + "/invoice/save", {
    method: "post",
    headers: authHeader(),
    data: invoice,
  });
};

const getInvoiceById = (id) => {
  return axios.get(API_URL + `/invoice/${id}`, { headers: authHeader() });
};
const viewResellerPDF = (id) => {
  return axios.get(API_URL + `/reseller/invoice/view/${id}`, {
    headers: authHeader(),
  });
};

const viewPDF = (id, regenerate) => {
  return axios.get(API_URL + `/invoice/view/${id}/?regenerate=${regenerate}`, {
    headers: authHeader(),
  });
};
const send = (invoice) => {
  return axios(API_URL + "/invoice/send", {
    method: "post",
    headers: authHeader(),
    data: invoice,
  });
};

const pay = (data) => {
  return axios(API_URL + "/invoice/pay", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};
const createCheckoutURL = (data) => {
  return axios(API_URL + "/checkout", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

export default {
  deleteInvoice,
  viewResellerPDF,
  postInvoice,
  getInvoiceById,
  viewPDF,
  send,
  pay,
  createCheckoutURL,
};
