import React from "react";
import EventBus from "./EventBus";
import BranchService from "../services/branch.service";
import CompanyService from "../services/company.service";
import InventoryService from "../services/inventory.service";
import CommonService from "../services/common.service";
import PackageService from "../services/package.service";
import FollowupService from "../services/followup.service.js";

export const getBranches = async (filter, fullList) => {
  let res = await BranchService.getBranchesList(filter, fullList).then(
    (response) => {
      return response.data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};

export const getCompanies = async (branch) => {
  let res = await CompanyService.getCompaniesList(branch).then(
    (response) => {
      return response.data.data && response.data.data.list
        ? response.data.data.list
        : [];
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};
export const getCashlogCategories = async (branch) => {
  let res = await CommonService.getCashlogCategories(branch).then(
    (response) => {
      return response.data.data ? response.data.data : [];
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};

export const getCategories = async (branch, usage) => {
  let res = await CommonService.getCategories(branch, usage).then(
    (response) => {
      return response.data.data.list;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};

export const getBranchAccounts = async (branch) => {
  let res = await CommonService.getBranchAccounts(branch).then(
    (response) => {
      return response.data.data.accounts;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};

export const getSuppliers = async (branch) => {
  let res = await CommonService.getSuppliers(branch).then(
    (response) => {
      return response.data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};
export const getBranchJourneyQuestions = async () => {
  let res = await CommonService.getBranchJourneyQuestions().then(
    (response) => {
      return response.data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );

  return res;
};

export const getFormsList = async (branch, type) => {
  let res = await CommonService.getFormsList(branch, type).then(
    (response) => {
      return response.data.data && response.data.data.list
        ? response.data.data.list
        : [];
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};

export const getInventoriesOptions = async (
  onlyAccessories,
  invType,
  branch,
  offset,
  limit,
  onlyList,
  label,
  model,
  archived
) => {
  let res = await InventoryService.getInventoriesList(
    onlyAccessories,
    invType,
    branch,
    offset,
    limit,
    onlyList,
    label,
    model,
    archived
  ).then(
    (response) => {
      return response.data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  return res;
};

export const getPlansList = async (branch, parentBranch) => {
  let res = await CommonService.getPlansList(branch, parentBranch).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );
  return res;
};

export const getBikersList = async (branch, searchKey, isSearchArray) => {
  let res = await CommonService.getBikerList(
    branch,
    searchKey,
    isSearchArray
  ).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getModels = async (
  label,
  branch,
  offset,
  limit,
  onlyList,
  type
) => {
  let res = await CommonService.getModelsList(
    label,
    branch,
    offset,
    limit,
    onlyList,
    type
  ).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getAvailableChargers = async (branch, source) => {
  let res = await CommonService.getAvailableChargers(branch, source).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getAvailableBats = async (branch, source) => {
  let res = await CommonService.getAvailableBats(branch, source).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getAvailableBikes = async (branch, source, onlyNumber, action) => {
  let res = await CommonService.getAvailableBikes(
    branch,
    source,
    onlyNumber,
    action
  ).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getInventoryByType = async (invType, branch) => {
  let res = await CommonService.getInventoryByType(invType, branch).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getInvoicesList = async (
  bikerId,
  onlySent,
  branch,
  invType,
  offset,
  limit,
  onlyList,
  keyword,
  start,
  end,
  paid,
  number,
  period,
  plan,
  isDraft,
  searchByFilter,
  hasAccessoriesFilter
) => {
  let res = await CommonService.getInvoicesList(
    bikerId,
    onlySent,
    branch,
    invType,
    offset,
    limit,
    onlyList,
    keyword,
    start,
    end,
    paid,
    number,
    period,
    plan,
    isDraft,
    searchByFilter,
    hasAccessoriesFilter
  ).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getSubscriptionsList = async (
  bikerId,
  noInvoice,
  branch,
  offset,
  limit,
  sent,
  keyword,
  nextRenewal,
  statusFilter,
  planFilter,
  start,
  end,
  source
) => {
  //console.log(keyword);
  let res = await CommonService.getSubscriptionsList(
    bikerId,
    noInvoice,
    branch,
    offset,
    limit,
    sent,
    keyword,
    nextRenewal,
    statusFilter,
    planFilter,
    start,
    end,
    source
  ).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getLastSubscribtion = async (bikerId) => {
  let res = await CommonService.getLastSubscribtion(bikerId).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getCountriesList = async () => {
  let res = await CommonService.getCountries().then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );

  return res;
};

export const getJourneysList = async (offset, limit, userId) => {
  let res = await CommonService.getJourneys(offset, limit, userId).then(
    (response) => {
      return response.data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  return res;
};

export const getPackages = async (branch) => {
  let res = await PackageService.getPackagesList(branch).then(
    (response) => {
      return response.data.data && response.data.data.list
        ? response.data.data.list
        : [];
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};

export const getFollowups = async (subUrl) => {
  let res = await FollowupService.getFollowups(subUrl).then(
    (response) => {
      return response.data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  return res;
};

export const getAccountantInvoices = async (
  start,
  end,
  invoiceType,
  categoryFilter
) => {
  let res = await CommonService.getAccountantInvoices(
    start,
    end,
    invoiceType,
    categoryFilter
  ).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );
  return res;
};

export const getAccountantTransactions = async (start, end, categoryFilter) => {
  let res = await CommonService.getAccountantTransactions(
    start,
    end,
    categoryFilter
  ).then(
    ({ data }) => {
      return data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      alert(_content);
      if (error.response && error.response.status === 401)
        EventBus.dispatch("logout");
    }
  );
  return res;
};

export const getCompanyResellerDetails = async () => {
  let res = await CompanyService.getCompanyResellerDetails().then(
    (response) => {
      return response.data.data;
    },
    (error) => {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      alert(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  );
  //console.log(res)
  //alert("returning")
  return res;
};
