import React from "react";
import { Row, Col } from "react-bootstrap";
import mapPic from "../images/map.png";
export const RESET_MSG = "Please select your filters, then click go.";
export const CONFIDENTIAL_INFO = "Those information are confidential!";
export const NO_RESULTS = "No results found!";
export const INVALID_PASSWORD = (
  <p>
    Your password must contain a minimum of 8 characters including:
    <br />- At least 1 uppercase.
    <br />- At least 1 digit.
  </p>
);
export const PASSWORD_CHANGED = "Password has been changed.";
export const iAgree1 = (
  <div style={{ paddingTop: 15 }}>
    <p>
      I, the undersigned, by signing this Waiver, I assume all risks
      participating in the bicycle testing, training, promotion, or{" "}
      <span style={{ color: "red", textDecoration: "underline" }}>
        generally
      </span>
      the use (the "Activity") of the bicycles or electric bicycles provided to
      me (the "Bicycle" or{" "}
      <span style={{ color: "red", textDecoration: "underline" }}>
        "Wave Bike"
      </span>
      ).Without signing this form, I will not be able to participate in the
      Activity or the use of Bicycles. I acknowledge that the above Activity or
      use of Bicycles may pose some risk of personal injury and that I undertake
      and assume this risk for myself. I further waive and release the promoters
      of the Activity and manufacturers of Bicycle, the company Wave Bike SARL
      and the company Progoris B.V., and their employees, agents, and other
      personnel involved in any way, any insuring entity of the above, and their
      directors, board members, officers, employees, volunteers, agents,
      representatives, or assigns, as well as the Activity sponsors (the
      "Personnel"), from any and all liability, including, but not limited to,
      liability arising from negligence or fault of the entities or persons for
      any injury or disability which may occur as a result of my participation
      in the above Activity or use of Bicycle. I am assuming all risks that may
      arise from negligence or carelessness on the part of any of the persons or
      entities and generally, the Personnel being released, as well as from
      defective equipment, real property, or personal property that is owned,
      maintained, or controlled by the above persons.
    </p>
    <p style={{ color: "red", textDecoration: "underline" }}>
      I also undertake to ensure that the Bicycle in my possession (i) will be
      always locked with the two available locks (wheel lock and chain lock) in
      a way that it cannot be moved once locked, (ii) be always parked in a safe
      place, (iii) has its keys and battery removed while parked. If the Bicycle
      is subjected to any damages or theft due to my non-abidance by this
      clause, I hereby undertake in a final and irrevocable way to pay to Wave
      Bike SARL and Progoris B.V. the amount of USD 500 (five hundred United
      States Dollars) in reparation for the damages incurred by Wave Bike SARL
      and Progoris B,.V due to my non-abidance by this clause.
    </p>
    <p>
      I CERTIFY THAT I AM PHYSICALLY FIT AND SUFFICIENTLY PREPARED FOR
      PARTICIPATION IN THE ACTIVITY OR USE OF BICYCLE AND THAT THERE ARE NO
      HEALTH-RELATED REASONS OR PROBLEMS WHICH WOULD PRECLUDE MY PARTICIPATION
      IN THE ACTIVITY OR USE OF BICYCLE. I HAVE NOT BEEN ADVISED OF ANY REASON
      WHICH WOULD LIMIT ME IN PARTICIPATING IN THE ACTIVITY.
    </p>

    <p>
      I UNDERSTAND AND AGREE THAT PARTICIPATION IN THE ACTIVITY OR USE OF
      BICYCLE CARRIES WITH IT THE RISK TO ME OF PERSONAL OR BODILY INJURY
      (INCLUDING DEATH) AND LOSS OR DAMAGE TO PROPERTY, AND I WILLINGLY AND
      KNOWINGLY ACCEPT THAT RISK. I ALSO UNDERSTAND AND AGREE THAT THE PERSONNEL
      (AND ANY MEMBER IN IT) DOES NOT AND WILL NOT GUARANTEE MY SAFETY DURING MY
      PARTICIPATION IN THE ACTIVITY OR USE OF BICYCLE. WITH THIS WAIVER, I,
      INDIVIDUALLY, AND ON BEHALF OF MY HEIRS, SUCCESSORS, ASSIGNS, AND PERSONAL
      REPRESENTATIVES, HEREBY RELEASE, RELIEVE, INDEMNIFY AND FOREVER DISCHARGE
      THE PERSONNEL AND ANY MEMBER OF IT, ITS EMPLOYEES, AGENTS, OFFICERS,
      TRUSTEES, AND REPRESENTATIVES (IN THEIR OFFICIAL AND INDIVIDUAL
      CAPACITIES) FROM ANY AND ALL LIABILITY WHATSOEVER FOR ANY PERSONAL OR
      BODILY INJURY (INCLUDING DEATH) AND LOSS OF OR DAMAGE TO PROPERTY THAT I
      MAY SUSTAIN, INCLUDING BUT NOT LIMITED TO ANY CLAIMS, DEMANDS, ACTIONS,
      CAUSES OF ACTION, JUDGMENTS, DAMAGES, EXPENSES, AND COSTS, INCLUDING
      ATTORNEY"S FEES, WHICH ARISE OUT OF, RESULT FROM, OCCUR DURING OR ARE
      CONNECTED IN ANY MANNER WITH MY PARTICIPATION IN THE ACTIVITY OR USE OF
      BICYCLE, ANY RELATED OR INDEPENDENT TRAVEL, IRRESPECTIVE OF WHETHER OR NOT
      THEY ARE SPONSORED, SUPERVISED OR CONTROLLED BY ANY OR THE PERSONNEL.
    </p>
    <p>
      I understand that I may be photographed while participating in the
      Activity or use of a bicycle. I agree to allow my photo, video, or film
      likeness to be used for any legitimate purpose by the Activity holders,
      sponsors, producers, and their assigns. I shall defend, hold harmless, and
      indemnify the parties from and against all losses, claims, damages, costs,
      or expenses (including reasonable legal fees, or similar costs) in
      connection with any action or claim brought or made (or threatened to be
      brought or made), for, or on account of any injuries or damages, received
      or sustained by myself arising during the course of the Activity or use of
      Bicycle.
    </p>
    <p>
      This Waiver constitutes the sole and only agreement between the parties
      concerning my release and indemnification as a condition for participating
      in this Activity or use of Bicycle. Any prior agreements, whether oral or
      in writing, shall be void and of no further effect. This Waiver may not be
      modified. I certify that I have read this document, and I fully understand
      its contents.
    </p>

    <p>
      I am aware that this is a release and indemnification of liability for
      myself, and I sign it of my own free will.
    </p>
  </div>
);

export const iAgree2_signed_intro = (
  <p>You have agreed in the following points:</p>
);
export const iAgree2_not_signed_intro = (
  <p style={{ paddingTop: 15 }}>
    Kindly read the below points with care, to make sure this information is
    clear to you.
  </p>
);

export const iAgree2 = (
  <div>
    <ol>
      <li>
        At the moment, Wave does not provide roadside assistance. If you
        encounter any issues with the bike, it will be your responsibility to
        bring it to our team for servicing. In case you cannot manage to
        transport the bike yourself, we can assist you at an extra charge
        depending on location, within 72 hours.
      </li>

      <li>
        Your subscription includes full maintenance and servicing of the bike.
        All repairs must be carried out exclusively by the Wave team, as
        third-party or self-servicing is strictly prohibited. Any damage caused
        by misuse, accidents, or excessive wear and tear beyond normal usage
        will be charged to the subscriber.
      </li>
      <li>
        The subscription renews automatically each month. Payments should be
        made at least five days before the renewal date, with the latest
        possible payment being on the renewal date itself. A late fee of $5 per
        day applies to payments made after the renewal date.
      </li>
      <li>
        If you decide to terminate your subscription, the bike, battery, and
        charger must be returned no later than the final day of your
        subscription to avoid a late return penalty of $5 per day.
      </li>
      <li>
        The helmet is yours to keep; however, if you terminate your subscription
        before completing three months, a $20 deduction will be made from your
        deposit to cover its subsidized cost.
      </li>
      <li>
        The Wave bike is designed for urban use only and should not be used for
        off-road riding.
      </li>
      <li>
        You are required to properly lock your bike using both locks and secure
        it to a fixed, non-removable object, such as a fence or streetlight. If
        the bike is not locked correctly with both locks, theft insurance will
        not cover any losses. For additional security, we strongly recommend
        storing the bike indoors whenever possible.
      </li>
      <li>
        Removing the battery overnight is mandatory. If the bike is left outside
        overnight with the battery attached, a $500 surcharge will apply in the
        case of theft.
      </li>

      <li>
        Upon subscribing, you will receive the following items in good working
        condition:
      </li>
    </ol>
    <Row style={{ flexDirection: "row", alignItems: "flex-start" }}>
      <Col md={5}>
        <b>and I will return:</b>
        <ul>
          <li>Wave bike</li>
          <li>Battery 48V-10.2Ah</li>
          <li>Charger 48V-2A (cord + adapter)</li>
          <li>Lock chain</li>
          <li>Two keys</li>
        </ul>
      </Col>
      <Col md={4}>
        <b>
          These items must be returned upon termination, with the exception of
          the helmet.
        </b>
      </Col>
    </Row>
  </div>
);
export const PHONE_REQUIRED = "Phone is required.";
export const CARD_SHARE_INTERNAL =
  "Your id card is solely for internal use and will not be shared with any third parties.";

/**<a
        target="_blank"
        rel="noopener noreferrer"
        href={process.env.REACT_APP_SERVER_API + "/upload/map.png"}
      >
        <img
          className="scrollImg"
          src={mapPic}
          width={300}
          height={200}
          title="Service area Wave (13-04-2021)"
          alt="Service area Wave (13-04-2021)"
        />
      </a> */
