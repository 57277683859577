import React, { useState } from "react";
import commonFunctions from "../../common/Functions";
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import "./overview.css";

const OverviewSection = ({
  userData,
  activeTab,
  cumulativeInvoiceAmount,
  userId,
}) => {
  const { internalNote, userBalance, type } = userData;
  const [iAgree, setIAgree] = useState(userData.iagree);

  // Function to format cumulative invoice amounts
  const renderCumulativeAmounts = () => {
    if (
      !cumulativeInvoiceAmount ||
      Object.keys(cumulativeInvoiceAmount).length === 0
    ) {
      return <p>No cumulative invoice amounts available.</p>;
    }

    return (
      <div className="cumulative-amounts-list">
        {Object.entries(cumulativeInvoiceAmount).map(
          ([currency, amount], index) => (
            <p className="section-content">
              {`${commonFunctions.CommaFormatted(amount)} ${currency}`}
            </p>
          )
        )}
      </div>
    );
  };

  const downloadIagree = async (regenerate) => {
    await UserService.getIagree(userId, regenerate).then(
      (res) => {
        if (res.data) {
          window.open(
            process.env.REACT_APP_SERVER_API + "/" + res.data.data.path,
            "_blank"
          );
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const signIAgree = () => {
    if (
      window.confirm(
        "Are you sure you want to sign the agreement for this user?"
      )
    ) {
      UserService.signIagree({
        userid: userId,
      }).then(
        () => {
          setIAgree(true);
          alert("Terms and conditions are agreed.");
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  return (
    <div
      id="overview"
      className={`tab-content ${
        activeTab === "overview" ? "expanded" : "collapsed"
      }`}
    >
      <div className="overview-section">
        <h3 className="section-title">User Balance</h3>
        <p className="section-content">
          {userBalance ? `${userBalance} USD` : "N/A"}
        </p>
      </div>

      <div className="overview-section">
        <h3 className="section-title">Cumulative Invoice Amount</h3>
        {renderCumulativeAmounts()}
      </div>

      <div className="overview-section">
        <h3 className="section-title">Internal Note</h3>
        <p className="section-content">{internalNote || "N/A"}</p>
      </div>

      {type === "registered" || type === "subscriber" ? (
        <div className="overview-section">
          <h3 className="section-title">iAgree</h3>
          <div className="agreement-section">
            {iAgree || userData.iagree ? (
              <span className="agreement-status">Signed</span>
            ) : (
              <a
                href="javascript:void(0)"
                onClick={() => signIAgree()}
                className="sign-agreement"
              >
                Sign
              </a>
            )}{" "}
            |{" "}
            <a
              href="javascript:void(0)"
              onClick={() => downloadIagree()}
              className="sign-agreement"
            >
              Download
            </a>{" "}
            <a
              href="javascript:void(0)"
              onClick={() => downloadIagree(true)}
              className="regenerate-pdf"
            >
              <i className="fa fa-redo"></i>
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OverviewSection;
