import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  getAccountantInvoices,
  getAccountantTransactions,
  getCashlogCategories,
} from "../common/getFunctions";
import * as labels from "../labels/en";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Accordion } from "react-bootstrap";
import { CSVLink } from "react-csv";
import commonFunctions from "../common/Functions";
import NewSelect from "react-select";
import { invoicesTypeList } from "../labels/options_en";
import PullDown from "../commonComponents/PullDown";

const AccountantDashboard = () => {
  const monthsNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const barColors = [
    "#368b85",
    "#0db66e",
    "#3db3e4",
    "#8e569f",
    "#eecd63",
    "#ffae94",
    "#dac1b9",
    "#e5867b",
  ];
  const lastQuarter = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 3,
    new Date().getDate()
  );
  const [fromDate, setFromDate] = useState(lastQuarter);
  const [toDate, setToDate] = useState(new Date());
  const [fromTransDate, setFromTransDate] = useState(lastQuarter);
  const [toTransDate, setToTransDate] = useState(new Date());
  const [showInvoices, setShowInvoices] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [invoicesData, setInvoicesData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTrans, setLoadingTrans] = useState(false);
  const lebaneseRate = 100000;
  const [invoiceType, setInvoiceType] = useState("");
  const [resetMsg, setResetMsg] = useState("");
  const [resetTMsg, setResetTMsg] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categories, setCategories] = useState([]);

  const invoicesHeader = [
    { label: "Number", key: "Number" },
    { label: "CLIENT NAME", key: "CLIENT NAME" },
    { label: "INVOICE DATE", key: "INVOICE DATE" },
    { label: "Start Date", key: "Start Date" },
    { label: "End Date", key: "End Date" },
    { label: "DESCRIPTION", key: "DESCRIPTION" },
    { label: "QUANTITY", key: "QUANTITY" },
    { label: "Rate", key: "Rate" },
    { label: "PRICE (EX VAT)", key: "PRICE (EX VAT)" },
    { label: "PRICE (INCL VAT)", key: "PRICE (INCL VAT)" },
    { label: "Currency", key: "CURRENCY" },
    { label: "VAT", key: "VAT" },
    { label: "Account", key: "ACCOUNT" },
    { label: "Paid", key: "Paid" },
    { label: "Completed", key: "Completed" },
    { label: "transactionNumber", key: "transactionNumber" },
    { label: "Paid At", key: "Paid At" },
    { label: "Category", key: "CATEGORY" },
    { label: "Category Key", key: "Category Key" },
    { label: "Category2", key: "CATEGORY2" },
    { label: "Category Key2", key: "Category Key2" },
    { label: "transactionAmount1", key: "transactionAmount1" },
  ];

  const transactionHeader = [
    { label: "Transaction Number", key: "Transaction Number" },
    { label: "Label", key: "Label" },
    { label: "Amount (EX VAT)", key: "Amount without Vat" },
    { label: "VAT", key: "VAT" },
    { label: "Amount", key: "Amount" },
    { label: "Currency", key: "Currency" },
    { label: "Category", key: "Category" },
    { label: "Category Key", key: "Category Key" },
    { label: "Account", key: "Account" },
    { label: "Invoice Number", key: "Invoice Number" },
    { label: "Cash Date", key: "Cash Date" },
  ];

  const invoiceToolTip = ({ active, payload, label }) => {
    if (active) {
      let currency = "";

      let invoiceObj = invoicesData.filter((i) => {
        return i.name === payload[0].name;
      });

      if (invoiceObj && invoiceObj[0]) {
        return (
          <div
            className="custom-tooltip"
            style={{
              backgroundColor: "#ffff",
              padding: "5px",
              border: "1px solid #cccc",
            }}
          >
            <label>
              {payload[0].name} :{" "}
              {commonFunctions.CommaFormatted(
                invoiceObj[0].currency == "LBP"
                  ? payload[0].value * lebaneseRate
                  : payload[0].value
              )}
            </label>
          </div>
        );
      }
    }
    return null;
  };

  const transactionsToolTip = ({ active, payload, label }) => {
    if (active) {
      let currency = "";

      let transactionObj = transactionData.filter((t) => {
        return t.name === payload[0].name;
      });

      if (transactionObj && transactionObj[0]) {
        return (
          <div
            className="custom-tooltip"
            style={{
              backgroundColor: "#ffff",
              padding: "5px",
              border: "1px solid #cccc",
            }}
          >
            <label>
              {payload[0].name} :{" "}
              {commonFunctions.CommaFormatted(
                transactionObj[0].currency == "LBP"
                  ? payload[0].value * lebaneseRate
                  : payload[0].value
              )}
            </label>
          </div>
        );
      }
    }
    return null;
  };
  const currentUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setCategories(await getCashlogCategories(currentUser.branch));
      await triggerSearch(lastQuarter, new Date(), "");
      await triggerTransSearch(lastQuarter, new Date(), "");
    }
    setCategoryFilter("");
    onReady();
  }, []);

  useEffect(() => {
    setInvoices([]);
    setResetMsg(labels.RESET_MSG);
  }, [fromDate, toDate, invoiceType]);

  useEffect(() => {
    setTransactions([]);
    setResetTMsg(labels.RESET_MSG);
  }, [fromTransDate, toTransDate, categoryFilter]);

  const triggerTransSearch = async (fromDateT, toDateT, categoryFilter) => {
    if (fromDateT) {
      setLoadingTrans(true);
      const transactionRes = await getAccountantTransactions(
        fromDateT,
        toDateT,
        categoryFilter
      );
      let data2 = [];
      let result2 = [];

      transactionRes.forEach(function (a) {
        const name = a.Account + "-" + a.Currency;
        let amount = commonFunctions.removeComma(a.Amount);

        if (a.CURRENCY === "LBP") {
          amount = amount / lebaneseRate;
        }
        if (a.Account) {
          if (!this[name]) {
            this[name] = { name: name, amount: 0, currency: a.Currency };
            result2.push(this[name]);
          }
          this[name].amount += Number(commonFunctions.removeComma(amount));
        }
      }, Object.create(null));

      result2 &&
        result2.map((s, i) => {
          data2.push({
            name: s.name,
            value: s.amount,
            currency: s.currency,
          });
        });
      setResetTMsg("");
      setTransactionData(data2);
      setTransactions(transactionRes);
      setLoadingTrans(false);
    }
  };

  const triggerSearch = async (fromDateT, toDateT, invoiceType) => {
    if (fromDateT) {
      setLoading(true);
      const invoicesRes = await getAccountantInvoices(
        fromDateT,
        toDateT,
        invoiceType,
        categoryFilter
      );
      setInvoices(invoicesRes);
      setResetMsg("");

      let data = [];
      let result = [];

      invoicesRes.forEach(function (a) {
        const name = a.ACCOUNT + "-" + a.CURRENCY;
        let amount = commonFunctions.removeComma(a["PRICE (INCL VAT)"]);
        if (a.CURRENCY === "LBP") {
          amount = amount / lebaneseRate;
        }
        if (a.ACCOUNT) {
          if (!this[name]) {
            this[name] = { name: name, amount: 0, currency: a.CURRENCY };
            result.push(this[name]);
          }
          this[name].amount += Number(commonFunctions.removeComma(amount));
        }
      }, Object.create(null));

      result &&
        result.map((s, i) => {
          data.push({
            name: s.name,
            value: s.amount,
            currency: s.currency,
          });
        });

      setInvoicesData(data);
      setLoading(false);
    }
  };

  const resetSearch = async () => {
    setFromDate(lastQuarter);
    setToDate(new Date());
    await triggerSearch(lastQuarter, new Date(), invoiceType);
  };

  const resetTransactionSearch = async () => {
    setFromTransDate(lastQuarter);
    setToTransDate(new Date());
    await triggerTransSearch(lastQuarter, new Date(), categoryFilter);
  };

  const handleTypeChange = async (type) => {
    setInvoiceType(type);
  };
  const handleCategoryChange = async (cat) => {
    setCategoryFilter(cat);
  };
  return (
    <div>
      <h3>Invoices List</h3>
      <div className="filterContainer">
        <div className="d-flex flex-row">
          <Col lg={2} className="p-0 pl-3">
            <label
              htmlFor="start"
              className="filterLabelInput maintenanceLabels"
            >
              From
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="yyyy-MM-dd"
              className="form-control filterInput2"
              name="start"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </Col>
          <Col lg={2} className="p-0 pl-3">
            <label htmlFor="to" className="filterLabelInput maintenanceLabels">
              To
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="yyyy-MM-dd"
              className="form-control filterInput2"
              name="end"
              selected={toDate}
              onChange={(date) => setToDate(date)}
            />
          </Col>
          <Col lg={3} className="p-0 pl-3">
            <label
              htmlFor="invoiceType"
              className="filterLabelInput maintenanceLabels"
            >
              Invoice Type
            </label>
            <NewSelect
              autocomplete="off"
              value={invoiceType}
              isMulti={true}
              onChange={(selectedType) => {
                handleTypeChange(selectedType);
              }}
              options={invoicesTypeList}
              isClearable
            />
          </Col>

          <Col lg={2} className="p-0">
            <div style={{ marginTop: 35 }} border="0">
              <div className="d-flex flex-row align-items-start">
                <button
                  onClick={(e) => triggerSearch(fromDate, toDate, invoiceType)}
                  className="btn btn-primary search-button"
                >
                  Go
                </button>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary reset-button"
                >
                  Reset
                </button>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {resetMsg != "" ? (
        <div className="defaultMsg">{resetMsg}</div>
      ) : (
        <>
          <div style={{ paddingLeft: 50, marginTop: 50 }}>
            <div className="floatRight ">
              {" "}
              <a
                href="javascript:void(0)"
                className="text-dark midText"
                onClick={() => setShowInvoices(!showInvoices)}
              >
                {showInvoices ? "Hide" : "Show"} Table
              </a>
            </div>
            <div className="floatRight ">
              <CSVLink
                data={invoices}
                headers={invoicesHeader}
                filename={
                  fromDate.getMonth() +
                  1 +
                  "-" +
                  fromDate.getFullYear() +
                  "" +
                  " to " +
                  (toDate.getMonth() + 1) +
                  "-" +
                  toDate.getFullYear() +
                  "-" +
                  "invoices.csv"
                }
              >
                <i alt="View" title="View" className="fa fa-download"></i>{" "}
                Export
              </CSVLink>
            </div>
            {!loading && invoices && invoices.length != 0 ? (
              <PieChart width={730} height={300}>
                <Pie
                  data={invoicesData}
                  color="#000000"
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                >
                  {invoicesData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={barColors[index % barColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={invoiceToolTip} />
                <Legend layout="vertical" verticalAlign="top" align="left" />
              </PieChart>
            ) : (
              ""
            )}
            {!loading && invoices && invoices.length != 0 ? (
              showInvoices && (
                <div>
                  <table className="styled-table">
                    <thead>
                      <tr>
                        <th>Number </th> <th>Client</th> <th>Date </th>
                        <th>Start-End</th>
                        <th>Price (INCL VAT) </th>
                        <th>ACCOUNT</th>
                        <th>Transaction Number</th>
                        <th>Paid At</th>
                        <th>Category 1</th>
                        <th>Category Key 1</th>
                        <th>Transaction Amount 1</th>
                        <th>Category 2</th>
                        <th>Category Key 2</th>
                        <th>Transaction Amount 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices && invoices.length != 0
                        ? invoices.map((i) => {
                            return (
                              <tr>
                                <td>{i.Number}</td>
                                <td>{i["CLIENT NAME"]}</td>
                                <td>{i["INVOICE DATE"]}</td>
                                <td>
                                  {i["Start Date"]} - {i["End Date"]}
                                </td>
                                <td>
                                  {commonFunctions.CommaFormatted(
                                    i["PRICE (INCL VAT)"]
                                  )}{" "}
                                  {i["CURRENCY"]}
                                </td>
                                <td>{i["ACCOUNT"]}</td>
                                <td>{i["transactionNumber"]}</td>
                                <td>{i["Paid At"]}</td>
                                <td>{i["CATEGORY"]}</td>
                                <td>{i["Category Key"]}</td>
                                <td>{i["transactionAmount1"]}</td>
                                <td>{i["CATEGORY2"]}</td>
                                <td>{i["Category Key2"]}</td>
                                <td>{i["transactionAmount2"]}</td>
                              </tr>
                            );
                          })
                        : "No record found"}
                    </tbody>
                  </table>
                </div>
              )
            ) : (
              <div> No record found.</div>
            )}
          </div>
        </>
      )}
      <div>
        <h3>Transactions List</h3>
        <div className="filterContainer">
          <div className="d-flex flex-row">
            <Col lg={2} className="p-0 pl-3">
              <label
                htmlFor="start"
                className="filterLabelInput maintenanceLabels"
              >
                From
              </label>
              <DatePicker
                autoComplete="off"
                dateFormat="yyyy-MM-dd"
                className="form-control filterInput2"
                name="fromTransDate"
                selected={fromTransDate}
                onChange={(date) => setFromTransDate(date)}
              />
            </Col>
            <Col lg={2} className="p-0 pl-3">
              <label
                htmlFor="to"
                className="filterLabelInput maintenanceLabels"
              >
                To
              </label>
              <DatePicker
                autoComplete="off"
                dateFormat="yyyy-MM-dd"
                className="form-control filterInput2"
                name="toTransDate"
                selected={toTransDate}
                onChange={(date) => setToTransDate(date)}
              />
            </Col>

            <Col lg={3}>
              <label
                className="filterLabelInput maintenanceLabels"
                htmlFor="categoryFilter"
              >
                Category
              </label>
              <PullDown
                defaultValue={categoryFilter}
                labelInside={false}
                pulldownFor={"categoryFilter"}
                forceBlank={true}
                setValue={handleCategoryChange}
                options={categories}
              />
            </Col>
            <Col lg={2} className="p-0">
              <div style={{ marginTop: 35 }} border="0">
                <div className="d-flex flex-row align-items-start">
                  <button
                    onClick={(e) =>
                      triggerTransSearch(fromDate, toDate, categoryFilter)
                    }
                    className="btn btn-primary search-button"
                  >
                    Go
                  </button>
                  <button
                    onClick={(e) => resetTransactionSearch(e)}
                    className="btn btn-primary reset-button"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
      {resetTMsg != "" ? (
        <div className="defaultMsg">{resetTMsg}</div>
      ) : (
        <div style={{ paddingLeft: 50, marginTop: 50 }}>
          <div className="floatRight ">
            <a
              href="javascript:void(0)"
              className="text-dark midText"
              onClick={() => setShowTransactions(!showTransactions)}
            >
              {showTransactions ? "Hide" : "Show"} Table
            </a>
          </div>
          <div className="floatRight ">
            <CSVLink
              data={transactions}
              headers={transactionHeader}
              filename={
                fromDate.getMonth() +
                1 +
                "-" +
                fromDate.getFullYear() +
                "" +
                " to " +
                (toDate.getMonth() + 1) +
                "-" +
                toDate.getFullYear() +
                "-" +
                "transactions.csv"
              }
            >
              <i alt="View" title="View" className="fa fa-download"></i> Export
            </CSVLink>
          </div>

          {!loadingTrans && transactions && transactions.length != 0 ? (
            <PieChart width={730} height={300}>
              <Pie
                data={transactionData}
                color="#000000"
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={120}
                fill="#8884d8"
              >
                {transactionData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={barColors[index % barColors.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={transactionsToolTip} />
              <Legend layout="vertical" verticalAlign="top" align="left" />
            </PieChart>
          ) : (
            ""
          )}

          {!loadingTrans && transactions && transactions.length != 0 ? (
            showTransactions && (
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>Label </th>
                    <th>Amount</th>
                    <th>Category</th>
                    <th>Account</th> <th>Invoice Number</th> <th>Cash Date</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions && transactions.length != 0
                    ? transactions.map((t) => {
                        return (
                          <tr>
                            <td>{t["Transaction Number"]}</td>
                            <td>{t.Label}</td>

                            <td>
                              {commonFunctions.CommaFormatted(t["Amount"])}{" "}
                              {t["Currency"]}
                            </td>
                            <td>
                              {t["Category"]} - {t["Category Key"]}
                            </td>
                            <td>{t["Account"]}</td>
                            <td>{t["Invoice Number"]}</td>
                            <td>{t["Cash Date"]}</td>
                          </tr>
                        );
                      })
                    : "No record found"}
                </tbody>
              </table>
            )
          ) : (
            <div> No record found.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountantDashboard;
