import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import { Helmet } from "react-helmet";
import ProtectedRoute from "./commonComponents/ProtectedRoute";

import Landing from "./LandingPage/Landing";
import Profile from "./components/Profile";
import Users from "./components/Users";
import Clients from "./components/Clients";
import UserForm from "./components/UserForm";
import User from "./containers/user";
import Journeys from "./components/Journeys";
import Journey from "./components/Journey";
import Subscriptions from "./components/Subscriptions";
import SubscriptionForm from "./components/SubscriptionForm";
import Subscription from "./components/Subscription";

import Bike from "./components/Bike";
import Bikes from "./components/Bikes";
import BikeForm from "./components/BikeForm";
import Model from "./components/Model";
import Models from "./components/Models";
import ModelForm from "./components/ModelForm";
import Charger from "./components/Charger";
import Chargers from "./components/Chargers";
import ChargerForm from "./components/ChargerForm";
import Maintenance from "./components/Maintenance";
import Maintenances from "./components/Maintenances";
import MaintenanceSummary from "./components/MaintenanceSummary";
import MaintenanceForm from "./components/MaintenanceForm";
import Inventory from "./components/Inventory";
import Inventories from "./components/Inventories";
import InventoryForm from "./components/InventoryForm";
import Bat from "./components/Bat";
import Bats from "./components/Bats";
import BatForm from "./components/BatForm";
import Plan from "./components/Plan";
import Plans from "./components/Plans";
import PlanForm from "./components/PlanForm";
import Branch from "./components/Branch";
import Branches from "./components/Branches";
import BranchForm from "./components/BranchForm";
import Company from "./components/Company";
import Companies from "./components/Companies";
import CompanyForm from "./components/CompanyForm";
import CashLog from "./components/CashLog";
import CashLogs from "./components/CashLogs";
import CashLogForm from "./components/CashLogForm";
import CashLogSummary from "./components/CashLogSummary";
import ClientPayments from "./components/ClientPayments";
import Invoice from "./components/Invoice";
import Invoices from "./components/Invoices";
import InvoiceForm from "./components/InvoiceForm";
import Package from "./components/Package";
import Packages from "./components/Packages";
import PackageForm from "./components/PackageForm";
import FaqForm from "./components/FaqForm";
import Faqs from "./components/Faqs";
import BikeLocation from "./components/BikeLocation";
import Categories from "./components/Categories";
import CategoryForm from "./components/CategoryForm";
import ChangePassword from "./components/ChangePassword";
import MenuController from "./components/MenuController";
import EditProfile from "./components/EditProfile";
import DashboardController from "./components/DashboardController";
import FollowUp from "./components/FollowUp";
import WaitingsUser from "./components/WaitingsUser";
import OnBoarding from "./components/OnBoarding";
import TermsAndConditions from "./components/TermsAndConditions";
import IdentityCard from "./components/IdentityCard";
import SurveyForm from "./components/SurveyForm";
import SurveyForms from "./components/SurveyForms";
import HelpdeskQuestions from "./components/HelpdeskQuestions";
import HelpdeskQuestionForm from "./components/HelpdeskQuestionForm";
import SurveyFormView from "./components/SurveyFormView";
import SurveyQuestions from "./components/SurveyQuestions";
import SurveyQuestionsView from "./components/SurveyQuestionsView";
import SurveysList from "./components/SurveysList";
import SurveyAnswersView from "./components/SurveyAnswersView";
import EventForm from "./components/EventForm";
import Events from "./components/Events";
import Event from "./components/Event";
import AnalyticsSubscriptions from "./components/AnalyticsSubsciptions";
import AnalyticsTermination from "./components/AnalyticsTermination";
import AnalyticsRevenue from "./components/AnalyticsRevenue";
import AnalyticsRegistered from "./components/AnalyticsRegistered";
import SurveyFormAnalytics from "./components/SurveyFormAnalytics";
import MaintenanceRequests from "./components/MaintenanceRequests";
import ResellerInvoices from "./components/ResellerInvoices";

const App = () => {
  const [user, setUser] = useState("");

  useEffect(async () => {
    const user = (await AuthService.getCurrentUser()) || "";
    setUser(user);
    //console.log('user', user)
  }, []);

  if (!user) {
    return (
      <>
        <Helmet>
          <title>Wave</title>
          <meta
            name="description"
            content="Discover the benefits of electric bikes in Lebanon. Get yours today and say goodbye to traffic!"
          />
          <link rel="canonical" href="https://wave.bike" />
          <meta
            property="og:title"
            content="Wave | Electric Bikes for rent in Lebanon"
          />
          <meta
            property="og:description"
            content="Discover the beauty of Lebanon on two wheels with Wave's electric bike rentals. Explore the country's scenic routes, historic landmarks, and vibrant cities with ease and sustainability."
          />
          <meta property="og:image" content="/path/to/wave-logo.png" />
          <meta property="og:url" content="https://wave.bike" />
          <meta property="og:type" content="website" />
          <meta
            name="twitter:title"
            content="Wave | Electric Bikes for rent in Lebanon"
          />
          <meta
            name="twitter:description"
            content="Discover the beauty of Lebanon on two wheels with Wave's electric bike rentals. Explore the country's scenic routes, historic landmarks, and vibrant cities with ease and sustainability."
          />
        </Helmet>
        <Switch>
          <Route path="/" component={Landing} />
        </Switch>
      </>
    );
  }

  return (
    <div
      className={
        "wrapper d-flex align-items-stretch" +
        (user.type === "technical" ? " flex-column" : "")
      }
    >
      <MenuController />

      <div className="pt-10 h-100 pl-10 mobile">
        <Switch>
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/editprofile" component={EditProfile} />
          <Route exact path="/profile/:code" component={Profile} />
          <Route exact path="/changepassword" component={ChangePassword} />
          <Route exact path="/dashboard" component={DashboardController} />
          <Route exact path="/payments" component={ClientPayments} />
          <Route exact path="/invoices" component={Invoices} />
          <Route exact path="/subscriptions" component={Subscriptions} />
          <Route exact path="/journeys" component={Journeys} />
          <Route exact path="/journey/:id" component={Journey} />
          <Route
            exact
            path="/termsandconditions"
            component={TermsAndConditions}
          />
          <Route exact path="/identitycard" component={IdentityCard} />

          {/* admin  technical  manager*/}
          <ProtectedRoute
            path="/model/clone/:id"
            authorized={["admin", "technical", "manager"]}
            component={ModelForm}
          />
          <ProtectedRoute
            path="/model/new"
            authorized={["admin", "technical", "manager"]}
            component={ModelForm}
          />
          <ProtectedRoute
            path="/model/edit/:id"
            authorized={["admin", "technical", "manager"]}
            component={ModelForm}
          />
          <ProtectedRoute
            path="/model/:id"
            authorized={["admin", "technical", "manager"]}
            component={Model}
          />
          <ProtectedRoute
            path="/models"
            authorized={["admin", "technical", "manager"]}
            component={Models}
          />
          <ProtectedRoute
            path="/charger/new"
            authorized={["admin", "technical", "manager"]}
            component={ChargerForm}
          />
          <ProtectedRoute
            path="/charger/edit/:id"
            authorized={["admin", "technical", "manager"]}
            component={ChargerForm}
          />
          <ProtectedRoute
            path="/charger/clone/:id"
            authorized={["admin", "technical", "manager"]}
            component={ChargerForm}
          />
          <ProtectedRoute
            path="/charger/:id"
            authorized={["admin", "technical", "manager"]}
            component={Charger}
          />
          <ProtectedRoute
            path="/chargers"
            authorized={["admin", "technical", "manager"]}
            component={Chargers}
          />
          <ProtectedRoute
            path="/bike/clone/:id"
            authorized={["admin", "technical", "manager"]}
            component={BikeForm}
          />
          <ProtectedRoute
            path="/bike/new"
            authorized={["admin", "technical", "manager"]}
            component={BikeForm}
          />
          <ProtectedRoute
            path="/bike/edit/:id"
            authorized={["admin", "technical", "manager"]}
            component={BikeForm}
          />
          <ProtectedRoute
            path="/bike/:id"
            authorized={["admin", "technical", "manager"]}
            component={Bike}
          />
          <ProtectedRoute
            path="/bikes"
            authorized={["admin", "technical", "manager"]}
            component={Bikes}
          />
          <ProtectedRoute
            path="/bat/new"
            authorized={["admin", "technical", "manager"]}
            component={BatForm}
          />
          <ProtectedRoute
            path="/bat/edit/:id"
            authorized={["admin", "technical", "manager"]}
            component={BatForm}
          />
          <ProtectedRoute
            path="/bat/clone/:id"
            authorized={["admin", "technical", "manager"]}
            component={BatForm}
          />
          <ProtectedRoute
            path="/bat/:id"
            authorized={["admin", "technical", "manager"]}
            component={Bat}
          />
          <ProtectedRoute
            path="/bats"
            authorized={["admin", "technical", "manager"]}
            component={Bats}
          />
          <ProtectedRoute
            path="/inventory/clone/:id"
            authorized={["admin", "technical", "manager"]}
            component={InventoryForm}
          />
          <ProtectedRoute
            path="/inventory/new"
            authorized={["admin", "technical", "manager"]}
            component={InventoryForm}
          />
          <ProtectedRoute
            path="/inventory/edit/:id"
            authorized={["admin", "technical", "manager"]}
            component={InventoryForm}
          />
          <ProtectedRoute
            path="/inventory/:id"
            authorized={["admin", "technical", "manager"]}
            component={Inventory}
          />
          <ProtectedRoute
            path="/inventories"
            authorized={["admin", "technical", "manager"]}
            component={Inventories}
          />

          {/* admin  helpdesk*/}
          <ProtectedRoute
            path="/form/new/:type"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveyForm}
          />
          <ProtectedRoute
            path="/form/edit/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveyForm}
          />
          <ProtectedRoute
            path="/analytics/form/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveyFormAnalytics}
          />

          <ProtectedRoute
            path="/forms/:type"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveyForms}
          />
          <ProtectedRoute
            path="/form/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveyFormView}
          />
          <ProtectedRoute
            path="/questions/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveyQuestions}
          />
          <ProtectedRoute
            path="/answers/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveysList}
          />
          <ProtectedRoute
            path="/answer/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={SurveyAnswersView}
          />
          <ProtectedRoute
            path="/survey/:id"
            authorized={["public"]}
            component={SurveyQuestionsView}
          />
          <ProtectedRoute
            path="/analytics/subscriptions"
            authorized={["admin", "manager", "helpdesk"]}
            component={AnalyticsSubscriptions}
          />
          <ProtectedRoute
            path="/analytics/registered"
            authorized={["admin", "manager", "helpdesk"]}
            component={AnalyticsRegistered}
          />
          <ProtectedRoute
            path="/analytics/terminations"
            authorized={["admin", "manager", "helpdesk"]}
            component={AnalyticsTermination}
          />
          <ProtectedRoute
            path="/analytics/revenue"
            authorized={["admin", "manager", "accountant"]}
            component={AnalyticsRevenue}
          />
          <ProtectedRoute
            path="/event/new"
            authorized={["admin", "helpdesk", "manager"]}
            component={EventForm}
          />
          <ProtectedRoute
            path="/events"
            authorized={["admin", "manager", "helpdesk"]}
            component={Events}
          />
          <ProtectedRoute
            path="/event/edit/:id"
            authorized={["admin", "helpdesk", "manager"]}
            component={EventForm}
          />
          <ProtectedRoute
            path="/event/clone/:id"
            authorized={["admin", "helpdesk", "manager"]}
            component={EventForm}
          />
          <ProtectedRoute
            path="/event/:id"
            authorized={["admin", "helpdesk", "manager"]}
            component={Event}
          />

          <ProtectedRoute
            path="/onBoarding"
            authorized={["admin", "helpdesk", "manager"]}
            component={OnBoarding}
          />
          <ProtectedRoute
            path="/waitings"
            authorized={["admin", "helpdesk", "manager"]}
            component={WaitingsUser}
          />
          <ProtectedRoute
            path="/invoice/new"
            authorized={["admin", "helpdesk", "manager"]}
            component={InvoiceForm}
          />
          <ProtectedRoute
            path="/invoice/edit/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={InvoiceForm}
          />
          <ProtectedRoute
            path="/invoice/clone/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={InvoiceForm}
          />
          <ProtectedRoute
            path="/invoice/:id"
            authorized={[
              "admin",
              "manager",
              "helpdesk",
              "registered",
              "subscriber",
            ]}
            component={Invoice}
          />
          {/*<ProtectedRoute path="/invoices" authorized={["admin", "manager", "helpdesk"]} component={Invoices} />*/}
          <ProtectedRoute
            path="/subscription/new"
            authorized={["admin", "manager", "helpdesk"]}
            component={SubscriptionForm}
          />
          <ProtectedRoute
            path="/subscription/edit/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={SubscriptionForm}
          />
          <ProtectedRoute
            path="/subscription/:id"
            authorized={[
              "admin",
              "manager",
              "helpdesk",
              "registered",
              "subscriber",
            ]}
            component={Subscription}
          />
          {/*<ProtectedRoute path="/subscriptions" authorized={["admin", "manager", "helpdesk"]} component={Subscriptions} />*/}
          <ProtectedRoute
            path="/company/new"
            authorized={["admin", "manager", "helpdesk"]}
            component={CompanyForm}
          />
          <ProtectedRoute
            path="/company/clone/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={CompanyForm}
          />
          <ProtectedRoute
            path="/company/edit/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={CompanyForm}
          />
          <ProtectedRoute
            path="/reseller/requests"
            authorized={[
              "admin",
              "manager",
              "helpdesk",
              "technical",
              "reseller",
            ]}
            component={MaintenanceRequests}
          />

          <ProtectedRoute
            path="/reseller/invoices/:id"
            authorized={["admin", "helpdesk", "manager", "reseller"]}
            component={ResellerInvoices}
          />
          <ProtectedRoute
            path="/reseller/invoices"
            authorized={["admin", "helpdesk", "manager", "reseller"]}
            component={ResellerInvoices}
          />
          <ProtectedRoute
            path="/company/:id"
            authorized={["admin", "manager", "helpdesk", "reseller"]}
            component={Company}
          />
          <ProtectedRoute
            path="/companies"
            authorized={["admin", "manager", "helpdesk"]}
            component={Companies}
          />

          <ProtectedRoute
            path="/cashlog/summary"
            authorized={["admin", "manager", "helpdesk", "accountant"]}
            component={CashLogSummary}
          />
          <ProtectedRoute
            path="/cashlog/new"
            authorized={["admin", "manager", "helpdesk"]}
            component={CashLogForm}
          />
          <ProtectedRoute
            path="/cashlog/clone/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={CashLogForm}
          />
          <ProtectedRoute
            path="/cashlog/edit/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={CashLogForm}
          />
          <ProtectedRoute
            path="/cashlog/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={CashLog}
          />
          <ProtectedRoute
            path="/cashlogs"
            authorized={["admin", "manager", "helpdesk", "accountant"]}
            component={CashLogs}
          />

          <ProtectedRoute
            path="/package/new"
            authorized={["admin", "manager", "helpdesk"]}
            component={PackageForm}
          />
          <ProtectedRoute
            path="/package/edit/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={PackageForm}
          />
          <ProtectedRoute
            path="/package/:id"
            authorized={["admin", "manager", "helpdesk"]}
            component={Package}
          />
          <ProtectedRoute
            path="/packages"
            authorized={["admin", "manager", "helpdesk"]}
            component={Packages}
          />
          <ProtectedRoute
            path="/followup"
            authorized={["admin", "manager", "helpdesk"]}
            component={FollowUp}
          />

          {/* Registered - Subscriber 
<ProtectedRoute path="/waiting" authorized={["registered", "subscriber"]} component={WaitingsClient} /> */}

          <Route path="/maintenance/new" component={MaintenanceForm} />
          <Route path="/maintenance/edit/:id" component={MaintenanceForm} />
          <Route path="/maintenance/fix/:id" component={MaintenanceForm} />
          <Route path="/maintenance/:id" component={Maintenance} />
          <Route path="/maintenances" component={Maintenances} />
          <ProtectedRoute
            path="/dashboard/maintenance"
            authorized={["admin", "manager", "technical", "helpdesk"]}
            component={MaintenanceSummary}
          />
          <Route path="/faqs" component={Faqs} />
          <Route path="/faq/new" component={FaqForm} />
          <Route path="/faq/edit/:id" component={FaqForm} />
          <Route path="/faq/clone/:id" component={FaqForm} />
          <Route path="/locations" component={BikeLocation} />
          <Route path="/helpdesk" component={HelpdeskQuestions} />
          <Route path="/question/new" component={HelpdeskQuestionForm} />
          <Route path="/question/edit/:id" component={HelpdeskQuestionForm} />

          <ProtectedRoute
            path="/user/new"
            authorized={["admin", "manager"]}
            component={UserForm}
          />
          <ProtectedRoute
            path="/user/edit/:id"
            authorized={["admin", "manager"]}
            component={UserForm}
          />
          <ProtectedRoute
            path="/user/:id"
            authorized={["admin", "helpdesk", "manager"]}
            component={User}
          />
          <ProtectedRoute
            path="/clients"
            authorized={["admin", "helpdesk", "manager"]}
            component={Clients}
          />
          <ProtectedRoute
            path="/users"
            authorized={["admin", "manager"]}
            component={Users}
          />
          <ProtectedRoute
            path="/plan/new"
            authorized={["admin", "manager"]}
            component={PlanForm}
          />
          <ProtectedRoute
            path="/plan/edit/:id"
            authorized={["admin", "manager"]}
            component={PlanForm}
          />
          <ProtectedRoute
            path="/plan/clone/:id"
            authorized={["admin", "manager"]}
            component={PlanForm}
          />
          <ProtectedRoute
            path="/plan/:id"
            authorized={["admin", "manager"]}
            component={Plan}
          />
          <ProtectedRoute
            path="/plans"
            authorized={["admin", "manager"]}
            component={Plans}
          />
          <ProtectedRoute
            path="/category/new"
            authorized={["admin", "manager"]}
            component={CategoryForm}
          />
          <ProtectedRoute
            path="/category/edit/:id"
            authorized={["admin", "manager"]}
            component={CategoryForm}
          />
          <ProtectedRoute
            path="/category/clone/:id"
            authorized={["admin", "manager"]}
            component={CategoryForm}
          />
          <ProtectedRoute
            path="/categories"
            authorized={["admin", "manager"]}
            component={Categories}
          />
          <ProtectedRoute
            path="/branch/new"
            authorized={["admin", "manager"]}
            component={BranchForm}
          />
          <ProtectedRoute
            path="/branch/edit/:id"
            authorized={["admin", "manager"]}
            component={BranchForm}
          />
          <ProtectedRoute
            path="/branch/clone/:id"
            authorized={["admin", "manager"]}
            component={BranchForm}
          />
          <ProtectedRoute
            path="/branch/:id"
            authorized={["admin", "manager"]}
            component={Branch}
          />
          <ProtectedRoute
            path="/branches"
            authorized={["admin", "manager"]}
            component={Branches}
          />

          <Route path="/*" component={DashboardController} />
        </Switch>
      </div>

      {/* <AuthVerify logOut={logOut}/> */}
    </div>
  );
};

export default App;
